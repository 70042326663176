import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  ModalBase,
  RadioButton,
  Select,
} from "../../../components";

import logo from "../../../static/img/logo.svg";
import imagePlaceholder from "../../../static/img/image_gallery.png";
import MobileVerificationModal from "./MobileVerificationModal";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { checkEmailDuplicate, signUp } from "../../../apis";
import { useRecoilState } from "recoil";
import { userIdState } from "../../../atoms";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

const steps = {
  auth_details: "auth_details",
  personal_details: "personal_details",
  mobile_auth: "mobile_auth",
};

const Register = () => {
  const [step, setStep] = useState(steps.auth_details);
  const [profileImage, setProfileImage] = useState({ file: null, url: "" });
  const [detailMessage, setDetailMessage] = useState("");
  const [userId, setUserId] = useRecoilState(userIdState);
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const [allChecked, setAllChecked] = useState(false);
  const [agreements, setAgreements] = useState({
    terms: false,
    privacy: false,
    agreement: false,
    age: false,
    marketing: false,
  });

  const [profile, setProfile] = useState({
    email: "",
    password1: "",
    password2: "",
    brand_name: "",
    brand_name_en: "",
    bio: "",
    business_type: "0",
    phone: "",
  });

  useEffect(() => {
    if (userId && userId !== null) {
      setStep(steps.personal_details);
    }
  }, [userId]);

  const handleChangeProfilePhoto = (e) => {
    const { files } = e.target;
    if (files.length === 0) return;

    const url = URL.createObjectURL(files[0]);
    setProfileImage({ file: files[0], url });
    e.target.value = "";
  };

  const handleChangeEditProfilePhoto = () => {
    document.getElementById("profilePhoto").click();
  };

  const handleRemoveProfilePhoto = () => {
    setProfileImage({ file: null, url: "" });
  };

  const handleChangeData = (e, _name, _value) => {
    let { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  // 이메일 중복체크
  const checkUserEmail = async () => {
    try {
      const data = await checkEmailDuplicate({
        data: { email: profile.email },
      });
      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
      setDetailMessage("");

      if (data.data.msg !== "USER" && data.data.msg !== "NOT_USER") {
        alert("이미 가입한 회원입니다.");
        navigate("/auth/login");
        return;
      } else {
        setUserId(data.data.id);
        setStep(steps.personal_details);
      }
    } catch (err) {
      setDetailMessage("이미 등록된 회원입니다");
      console.log(err);
    }
  };

  const handleKeypressRegister = (e) => {
    if (e.key === "Enter") {
      handleSubmitAuthDetail();
    }
  };
  // 유효성 검사
  const handleSubmitAuthDetail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const pwdRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,20}$/;

    if (!profile.email) {
      setDetailMessage("이메일을 입력해주세요");
    } else if (!emailRegex.test(profile.email)) {
      setDetailMessage("유효한 이메일 형식을 확인해주세요");
    } else if (!profile.password1 || !profile.password2) {
      setDetailMessage("비밀번호를 입력해주세요");
    } else if (profile.password1 !== profile.password2) {
      setDetailMessage("비밀번호가 일치하지 않습니다");
    } else if (!pwdRegex.test(profile.password1)) {
      setDetailMessage("영문,숫자,특수기호 혼합 8~20자");
    } else {
      checkUserEmail();
    }
  };

  // 전체선택
  const handleAllCheck = () => {
    const allAgreementsChecked = !allChecked;

    setAgreements({
      terms: allAgreementsChecked,
      privacy: allAgreementsChecked,
      agreement: allAgreementsChecked,
      age: allAgreementsChecked,
      marketing: allAgreementsChecked,
    });

    setAllChecked(allAgreementsChecked);
  };

  const handleChangeCheckbox = (name) => {
    setAgreements({
      ...agreements,
      [name]: !agreements[name],
    });
  };

  useEffect(() => {
    const isAnyFalse = Object.values(agreements).some(
      (value) => value === false
    );

    setAllChecked(!isAnyFalse);
  }, [agreements]);

  // 유효성 검사
  const validatePersonalDetail = () => {
    if (!profile.brand_name || profile.brand_name.length === 0) {
      setDetailMessage("브랜드명을 입력해주세요");
      return false;
    } else if (
      !agreements.terms ||
      !agreements.privacy ||
      !agreements.agreement ||
      !agreements.age
    ) {
      setDetailMessage("필수약관에 동의해주세요");
      return false;
    }
    return true;
  };

  // 완료시 휴대폰 인증
  const handleSubmitPersnolDetail = () => {
    const validate = validatePersonalDetail();
    if (validate) {
      setDetailMessage("");
      setStep(steps.mobile_auth);
    }
  };

  // 회원가입 + 작가입점
  const createdNewDesigner = async () => {
    const validate = validatePersonalDetail();
    if (!validate) return;

    try {
      const formData = new FormData();
      formData.append("email", profile.email);
      formData.append("password", profile.password1);
      formData.append("phone", phone);
      formData.append("checkMarketing", agreements.marketing);
      formData.append("brandName", profile.brand_name);
      formData.append("brandNameEn", profile.brand_name_en);
      formData.append("description", profile.bio);
      formData.append("businessType", profile.business_type);
      if (profileImage.file) {
        formData.append("image", profileImage.file);
      }

      const data = await signUp({ data: formData });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.error_string);
    }
  };

  // 기존유저 + 작가입점
  const createdDesignerWithUser = async () => {
    const validate = validatePersonalDetail();
    if (!validate) return;

    try {
      const formData = new FormData();
      formData.append("userId", userId);
      formData.append("checkMarketing", agreements.marketing);
      formData.append("brandName", profile.brand_name);
      formData.append("brandNameEn", profile.brand_name_en);
      formData.append("description", profile.bio);
      formData.append("businessType", profile.business_type);
      if (profileImage.file) {
        formData.append("image", profileImage.file);
      }

      const data = await signUp({ data: formData });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      toast.success("입점이 완료되었습니다");
      navigate("/auth/login");
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.error_string);
    }
  };
  const openLinkInNewTab = (url) => {
    const newTab = window.open(url, "_blank");
    newTab.focus();
  };

  return (
    <div className="w-full overflow-auto register_page f-center bg-bg-primary">
      <div className="w-full max-w-xs p-4">
        <div className="mb-[89px] f-center mt-[53px]">
          <Link to="/auth/login">
            <img src={logo} alt="logo" width={158} />
          </Link>
        </div>

        <div className="text-center mb-[41px]">
          <h2 className="text-primary font-medium text-[15px] mb-[7px]">
            작가등록
          </h2>
          <p className="font-bold text-[25px]">반가워요!</p>
        </div>

        {step === steps.auth_details && (
          <>
            <div className="inputs">
              <div className="mb-6 form_group">
                <label
                  htmlFor="r_email"
                  className="mb-3 block font-semibold text-[15px] relative"
                  aria-required="true"
                >
                  이메일 주소
                  <span className="w-1 h-1 rounded-full bg-primary absolute top-[2px] right-[207px]"></span>
                </label>
                <Input
                  id="r_email"
                  type="email"
                  variant="outlined"
                  className="!text-font-input h-[28px] placeholder-gray-light"
                  color="gray"
                  placeholder="example@zaccudaccu.com"
                  name="email"
                  value={profile?.email}
                  onChange={handleChangeData}
                  onKeyPress={handleKeypressRegister}
                  required
                />
              </div>

              <div className="form_group">
                <label
                  htmlFor="r_pass"
                  className="mb-3 block font-semibold text-[15px] relative"
                >
                  비밀번호
                  <span className="w-1 h-1 rounded-full bg-primary absolute top-[2px] right-[225px]"></span>
                </label>
                <Input
                  id="r_pass"
                  className="mb-2 !text-font-input h-[28px] placeholder-gray-light"
                  type="password"
                  variant="outlined"
                  color="gray"
                  placeholder="비밀번호를 입력해주세요"
                  name="password1"
                  value={profile.password1}
                  onChange={handleChangeData}
                  onKeyPress={handleKeypressRegister}
                  required
                />
                <Input
                  type="password"
                  variant="outlined"
                  className="!text-font-input h-[28px] placeholder-gray-light"
                  color="gray"
                  placeholder="비밀번호를 다시 입력해주세요"
                  name="password2"
                  value={profile.password2}
                  onChange={handleChangeData}
                  onKeyPress={handleKeypressRegister}
                  required
                />
              </div>
            </div>

            {detailMessage && (
              <p className="mt-2 text-xs text-center text-primary animate-wiggle">
                {detailMessage}
              </p>
            )}

            <div className="action mt-[61px]">
              <Button
                className="w-full"
                variant="contained"
                color="primary"
                size="lg"
                onClick={handleSubmitAuthDetail}
              >
                다 <span className="mx-2"></span> 음
              </Button>
            </div>
          </>
        )}

        {step === steps.personal_details && (
          <>
            <div className="profile_photo">
              <h4 className="font-medium text-[15px] mb-3">프로필 사진</h4>

              <div className="relative">
                <div className="relative w-[114px] h-[114px] object-cover bg-gray-200 rounded-full overflow-hidden f-center ml-20 cursor-pointer group">
                  <input
                    type="file"
                    accept="image/jpg, image/png, image/jpeg"
                    className="absolute top-0 bottom-0 left-0 right-0 z-10 opacity-0 cursor-pointer"
                    id="profilePhoto"
                    onChange={handleChangeProfilePhoto}
                  />
                  {!profileImage.file && (
                    <img
                      src={imagePlaceholder}
                      alt="profile_photo"
                      className="object-cover"
                      width="28"
                      height="28"
                    />
                  )}
                  {profileImage.file && (
                    <img
                      src={profileImage.url}
                      alt="profile_photo"
                      className="object-contain w-[114px] h-[114px] bg-white"
                    />
                  )}
                  {profileImage.file && (
                    <div className="w-[114px] h-[114px] absolute top-0 bottom-0 left-0 right-0 f-center bg-[rgba(225,225,225,0.3)] z-20 hidden group-hover:flex">
                      <Button
                        variant="contained"
                        color="primary"
                        icon
                        size="sm"
                        onClick={handleChangeEditProfilePhoto}
                      >
                        <FiEdit />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        icon
                        size="sm"
                        className="ml-2"
                        onClick={handleRemoveProfilePhoto}
                      >
                        <BsTrash />
                      </Button>
                    </div>
                  )}
                </div>
              </div>

              <ul className="mt-4 text-gray font-normal text-[12px]">
                <li>
                  · 권장 이미지 사이즈{" "}
                  <span className="text-primary">500x500px</span>
                </li>
                <li>
                  · <span className="text-primary">jpg / jpeg / png</span>{" "}
                  파일형식
                </li>
              </ul>
            </div>

            <div className="mt-8 inputs">
              <div className="mb-6 form_group">
                <label
                  htmlFor="r_name"
                  className="mb-2 block font-medium text-[15px] relative"
                >
                  작가 및 브랜드명(한글)
                  <span className="w-1 h-1 rounded-full bg-primary absolute top-[2px] right-[142px]"></span>
                </label>
                <Input
                  id="r_name"
                  variant="outlined"
                  color="gray"
                  className="!text-font-input h-[28px]"
                  placeholder="작가 및 브랜드명을 입력해주세요"
                  name="brand_name"
                  value={profile.brand_name}
                  onChange={handleChangeData}
                />
              </div>

              <div className="mb-6 form_group">
                <label
                  htmlFor="r_name"
                  className="mb-2 block font-medium text-[15px]"
                >
                  작가 및 브랜드명(영문)
                </label>
                <Input
                  id="r_name"
                  variant="outlined"
                  color="gray"
                  className="!text-font-input h-[28px]"
                  placeholder="작가 및 브랜드명을 입력해주세요"
                  name="brand_name_en"
                  value={profile.brand_name_en}
                  onChange={handleChangeData}
                />
              </div>

              <div className="mb-6 form_group">
                <label
                  htmlFor="r_brand"
                  className="mb-2 block font-medium text-[15px]"
                >
                  작가 및 브랜드 소개
                </label>
                <Input
                  id="r_brand"
                  variant="outlined"
                  color="gray"
                  className="!text-font-input h-[28px]"
                  placeholder="작가 및 브랜드를 소개해주세요"
                  name="bio"
                  value={profile.bio}
                  onChange={handleChangeData}
                />
                {/* <p className="pr-1 mt-1 text-sm text-gray-300 text-end">
                  {profile?.bio.length || "0"}/1000
                </p> */}
              </div>

              <div className="form_group mb-[44px] flex flex-col">
                <label
                  htmlFor="r_business"
                  className="mb-2 block font-medium text-[15px] relative"
                >
                  사업자 형태
                  <span className="w-1 h-1 rounded-full bg-primary absolute top-[2px] right-[210px]"></span>
                </label>
                <Select
                  id="r_business"
                  // defaultValue="개인 작가(사업자 등록을 하지 않았어요)"
                  name="business_type"
                  value={profile.business_type}
                  onChange={handleChangeData}
                  className="h-[28px] w-full pl-[12px] py-[0]"
                >
                  <option value="0">
                    개인 프리랜서
                  </option>
                  <option value="1">간이 사업자</option>
                  <option value="2">일반사업자(개인/법인)</option>
                </Select>
              </div>

              <div className="form_group">
                <div className="flex items-center pl-[2px]">
                  <RadioButton
                    className="text-[20px]"
                    onClick={handleAllCheck}
                    onChange={handleAllCheck}
                    checked={allChecked}
                  />
                  <p className="ml-2 text-[12px] font-medium">전체동의</p>
                </div>
                <hr className="border-gray-200 mt-[11px] mb-[9px]" />
                <div className="radio_group text-black text-sm pl-[5px] pr-[2px]">
                  <div className="flex justify-between mb-2">
                    <div className="flex items-center">
                      <RadioButton
                        className="text-[14px]"
                        checked={agreements.terms}
                        onChange={() => handleChangeCheckbox("terms")}
                      />
                      <p className="ml-2 text-[12px] font-normal">
                        (필수)
                        <span className="text-primary"> 이용약관 </span>동의
                      </p>
                    </div>
                    <button
                      className="text-gray-400 underline underline-offset-2 text-[12px] font-normal"
                      onClick={() =>
                        openLinkInNewTab(
                          "https://plip.kr/pcc/c3f86a84-2d89-4706-bc0f-e218d5df124f/privacy-policy"
                        )
                      }
                    >
                      보기
                    </button>
                  </div>

                  <div className="flex justify-between mb-2">
                    <div className="flex items-center">
                      <RadioButton
                        className="text-[14px]"
                        checked={agreements.privacy}
                        onChange={() => handleChangeCheckbox("privacy")}
                      />
                      <p className="ml-2 text-[12px] font-normal">
                        (필수)
                        <span className="text-primary">
                          개인정보 수집·이용{" "}
                        </span>
                        동의
                      </p>
                    </div>
                    <button
                      className="text-gray-400 underline underline-offset-2 text-[12px] font-normal"
                      onClick={() =>
                        openLinkInNewTab(
                          "https://plip.kr/pcc/c3f86a84-2d89-4706-bc0f-e218d5df124f/consent/5.html"
                        )
                      }
                    >
                      보기
                    </button>
                  </div>

                  <div className="flex justify-between mb-2">
                    <div className="flex items-center">
                      <RadioButton
                        className="text-[14px]"
                        checked={agreements.agreement}
                        onChange={() => handleChangeCheckbox("agreement")}
                      />
                      <p className="ml-2 text-[12px] font-normal">
                        (필수)
                        <span className="text-primary"> 입점약관 </span>
                        동의
                      </p>
                    </div>
                    <button className="text-gray-400 underline underline-offset-2 text-[12px] font-normal">
                      보기
                    </button>
                  </div>

                  <div className="flex items-center mb-2">
                    <RadioButton
                      className="text-[14px]"
                      checked={agreements.age}
                      onChange={() => handleChangeCheckbox("age")}
                    />
                    <p className="ml-2 text-[12px] font-normal">
                      (필수)
                      <span className="text-primary"> 만 14세 이상</span>
                      입니다.
                    </p>
                  </div>

                  <div className="flex justify-between mb-2">
                    <div className="flex items-center">
                      {" "}
                      <RadioButton
                        className="text-[14px]"
                        checked={agreements.marketing}
                        onChange={() => handleChangeCheckbox("marketing")}
                      />
                      <p className="ml-2 text-[12px] font-normal">
                        (선택)
                        <span className="text-primary"> 홍보 및 마케팅 </span>
                        정보 수신 동의
                      </p>
                    </div>

                    <button
                      className="text-gray-400 underline underline-offset-2 text-[12px] font-normal"
                      onClick={() =>
                        openLinkInNewTab(
                          "https://plip.kr/pcc/c3f86a84-2d89-4706-bc0f-e218d5df124f/consent/4.html"
                        )
                      }
                    >
                      보기
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {detailMessage && (
              <p className="mt-4 text-xs text-center text-primary">
                {detailMessage}
              </p>
            )}

            <div className="action mt-[56px] mb-5">
              {userId === null ? (
                <Button
                  className="w-full disabled:bg-gray-300"
                  variant="contained"
                  color="primary"
                  size="lg"
                  onClick={handleSubmitPersnolDetail}
                  disabled={
                    profile.brand_name.trim() === "" ||
                    !agreements.terms ||
                    !agreements.privacy ||
                    !agreements.agreement ||
                    !agreements.age
                  }
                >
                  휴대폰 인증하기
                </Button>
              ) : (
                <Button
                  className="w-full disabled:bg-gray-300"
                  variant="contained"
                  color="primary"
                  size="lg"
                  onClick={createdDesignerWithUser}
                  disabled={
                    profile.brand_name.trim() === "" ||
                    !agreements.terms ||
                    !agreements.privacy ||
                    !agreements.agreement ||
                    !agreements.age
                  }
                >
                  작가 입점하기
                </Button>
              )}
            </div>
          </>
        )}
      </div>

      {step === steps.mobile_auth && (
        <ModalBase>
          <MobileVerificationModal
            onClose={(e) => setStep(steps.personal_details)}
            createdNewDesigner={createdNewDesigner}
            setPhone={setPhone}
            phone={phone}
          />
        </ModalBase>
      )}
    </div>
  );
};

export default Register;
