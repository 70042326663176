import React from "react";
import classnames from "classnames";

import { usePagination, DOTS } from "./usePagination";
import "./pagination.css";

const Pagination = ({
  onPageChange,
  totalCount = 0,
  siblingCount = 1,
  currentPage = 1,
  pageSize = 10,
  className,
}) => {
  if (!onPageChange) {
    onPageChange = () => {};
  }

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 1) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames("pagination-container", {
        [className]: className,
      })}
    >
      {currentPage !== 1 && (
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          <div className="arrow left" />
        </li>
      )}
      {paginationRange.map((pageNumber, i) => {
        if (pageNumber === DOTS) {
          return (
            <li key={i} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={i}
            className={classnames("pagination-item", {
              "font-bold !text-primary underline": pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      {currentPage !== lastPage && (
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          <div className="arrow right" />
        </li>
      )}
    </ul>
  );
};

export default Pagination;
