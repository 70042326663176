import React, { useEffect, useState } from "react";
import { getProfile, getSellState, salesList, salesRecords } from "../../apis";

import { Button, Input, Pagination, Spinner } from "../../components";

import excelIcon from "../../static/img/excel.png";
import formatDate from "../../utils/formatDate";
import {
  formatCate,
  formatPeriod,
  formatPricewithComma,
  formatSubcate,
} from "../../utils/formatData";
import { useRecoilValue } from "recoil";
import { designerIdState } from "../../atoms";

const SalesRecords = () => {
  const designerId = useRecoilValue(designerIdState);
  const [period, setPeriod] = useState("");
  const [salesInfo, setSalesInfo] = useState([]);
  const [user, setUser] = useState({});
  const [count, setCount] = useState({});
  const [type, setType] = useState("none");
  const [selectedDate, setSelectedDate] = useState({
    sdate: "",
    edate: "",
  });

  const [page, setPage] = useState({
    currentPage: 1,
    totalCount: 0,
    pageSize: 15,
    loading: false,
  });

  const fetchData = async (params, headers) => {
    setPage({ ...page, loading: true });
    try {
      const { data } = await salesList(params, headers);

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      const { page, per_page, totalCount } = data;
      setSalesInfo(data.items);

      setPage({
        ...page,
        currentPage: page,
        pageSize: per_page,
        totalCount: totalCount,
        loading: false,
      });
    } catch (err) {
      setPage({ ...page, loading: false });

      console.log(err);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const { data } = await getProfile({
        id: designerId,
      });

      setUser(data.profile);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCount = async () => {
    try {
      const { data } = await getSellState();

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      setCount(data);
    } catch (err) {
      console.log(err);
    }
  };

  // 날짜 직접 선택
  const handleChangeDate = (e) => {
    setType("selectedDate");
    const { name, value } = e.target;
    setSelectedDate({ ...selectedDate, [name]: value });
    setPage({ ...page, currentPage: 1 });
  };

  // 기간 버튼으로 선택
  const handleChangePeriod = (e) => {
    setType("period");
    const newPeriod = e.target.value;
    if (newPeriod !== period) {
      setPeriod(newPeriod);
      setPage({ ...page, currentPage: 1 });
    }
  };

  const handlePageChange = (value) => {
    if (value === page.currentPage) return;
    setPage({ ...page, currentPage: value });
  };

  useEffect(() => {
    fetchCount();
    fetchUserInfo();
    fetchData({
      dpid: designerId,
      page: page.currentPage,
      per_page: page.pageSize,
    });
  }, []);

  useEffect(() => {
    const periodValue = formatPeriod(period);
    if (type === "period" && period !== "none") {
      const periodValue = formatPeriod(period);
      fetchData({
        dpid: designerId,
        sdate: periodValue.sdate,
        edate: periodValue.edate,
        page: page.currentPage,
        per_page: page.pageSize,
      });
    } else if (type === "selectedDate") {
      fetchData({
        dpid: designerId,
        sdate: selectedDate.sdate,
        edate: selectedDate.edate,
        page: page.currentPage,
        per_page: page.pageSize,
      });
    }
  }, [period, page.currentPage, selectedDate]);

  return (
    <div className="sales_record_page">
      <section className="page_header">
        <h2 className="text-base">판매기록</h2>
      </section>

      <hr className="border-gray-200 my-6" />

      <section className="sales_info">
        <h5 className="text-3xl font-medium leading-normal">
          {user?.brand_name} 작가님의 <br /> 이번 달 판매 수는{" "}
          <span className="text-primary font-semibold">
            {formatPricewithComma(count?.monthSalesCount) || "0"}
          </span>{" "}
          건 입니다 :)
        </h5>
        <div className="flex gap-8 flex-wrap md:flex-nowrap mt-8">
          <div className="f-center flex-col shadow-app2 rounded-3xl px-3 py-6 max-w-[245px] w-full">
            <h6 className="text-xl mb-4">누적 판매 수</h6>
            <p className="text-primary font-bold text-4xl">
              {formatPricewithComma(count?.totalSalesCount) || "0"}
              <span className="text-base text-gray-500 font-light ml-1">
                건
              </span>
            </p>
          </div>

          <div className="f-center flex-col shadow-app2 rounded-3xl px-3 py-6 max-w-[245px] w-full">
            <h6 className="text-xl mb-4">이번 달 판매 수</h6>
            <p className="text-primary font-bold text-4xl">
              {count?.monthSalesCount || "0"}
              <span className="text-base text-gray-500 font-light ml-1">
                건
              </span>
            </p>
          </div>
        </div>
      </section>

      <hr className="border-gray-200 my-6" />

      <section className="sales_records px-4 relative">
        <div className="sales_filtering flex items-center">
          <Button
            variant={period === "today" ? "contained" : "default"}
            color={period === "today" ? "primary" : "gray"}
            size="sm"
            className="!rounded-full"
            onClick={handleChangePeriod}
            value="today"
          >
            오늘
          </Button>
          <Button
            variant={period === "week" ? "contained" : "default"}
            color={period === "week" ? "primary" : "gray"}
            size="sm"
            className="!rounded-full ml-2 text-gray-400"
            onClick={handleChangePeriod}
            value="week"
          >
            1주일
          </Button>
          <Button
            variant={period === "month" ? "contained" : "default"}
            color={period === "month" ? "primary" : "gray"}
            size="sm"
            className="!rounded-full ml-2 text-gray-400"
            onClick={handleChangePeriod}
            value="month"
          >
            1개월
          </Button>
          <Button
            variant={period === "tmonth" ? "contained" : "default"}
            color={period === "tmonth" ? "primary" : "gray"}
            size="sm"
            className="!rounded-full ml-2 text-gray-400"
            onClick={handleChangePeriod}
            value="tmonth"
          >
            3개월
          </Button>
          <div className="max-w-xs ml-4">
            <Input
              type="date"
              variant="outlined"
              color="gray"
              name="sdate"
              className="!py-1"
              value={selectedDate.sdate}
              onChange={handleChangeDate}
            />
          </div>
          <span className="block ml-2">~</span>
          <div className="max-w-xs ml-2">
            <Input
              type="date"
              variant="outlined"
              color="gray"
              name="edate"
              className="!py-1"
              value={selectedDate.edate}
              onChange={handleChangeDate}
            />
          </div>
          <div className="ml-auto">
            <Button
              variant="outlined"
              color="green"
              size="sm"
              className="!rounded-full f-center"
            >
              <img
                src={excelIcon}
                alt="excel"
                height="19px"
                width="19px"
                className="object-contain mr-2"
              />
              엑셀로 내려받기
            </Button>
          </div>
        </div>

        <hr className="border-gray-300 my-5" />

        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-black border border-gray-300">
            <thead className="text-base bg-gray-100 border-b border-b-gray-300">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium border-r border-r-gray-300"
                >
                  결제일
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium border-r border-r-gray-300"
                >
                  대분류
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium border-r border-r-gray-300"
                >
                  소분류
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium border-r border-r-gray-300"
                >
                  상품명
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium border-r border-r-gray-300"
                >
                  정산가
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium border-r border-r-gray-300"
                >
                  할인율
                </th>
                <th scope="col" className="px-6 py-4 font-medium">
                  판매가
                </th>
              </tr>
            </thead>
            <tbody className="font-normal">
              {salesInfo.map((product, i) => (
                <tr
                  key={product.id || i}
                  className="bg-white border-b border-gray-300 last:border-b-0"
                >
                  <td className="px-6 py-3 border-r border-r-gray-300 whitespace-nowrap">
                    {formatDate(product.created_at)}
                  </td>
                  <td className="px-6 py-3 border-r border-r-gray-300">
                    {formatCate(product.cate) || "-"}
                  </td>
                  <td className="px-6 py-3 border-r border-r-gray-300">
                    {formatSubcate(product.subcate) || "-"}
                  </td>
                  <td className="px-6 py-3 border-r border-r-gray-300">
                    {product.product_name || "-"}
                  </td>
                  <td className="px-6 py-3 border-r border-r-gray-300">
                    {product.paid_price || "-"}
                  </td>
                  <td className="px-6 py-3 border-r border-r-gray-300">
                    {product.discounted_price || "-"}
                  </td>
                  <td className="px-6 py-3">{product.sell_price || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="pagination mt-10">
          <Pagination
            pageSize={page.pageSize}
            onPageChange={handlePageChange}
            totalCount={page.totalCount}
            currentPage={page.currentPage}
          />
        </div>

        {page.loading && (
          <div className="w-full min-h-[300px] f-center absolute top-0 left-0 right-0 bottom-0 z-10">
            <Spinner />
          </div>
        )}
      </section>
    </div>
  );
};

export default SalesRecords;
