import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input, ModalBase } from "../../../components";
import AuthContext from "../../../contexts/AuthContext";
import logo from "../../../static/img/logo.svg";
import cookieHelper from "../../../utils/cookieHelper";
import ForgotPassword from "../ForgotPassword/Model";
import { signIn } from "../../../apis";
import { useSetRecoilState } from "recoil";
import { designerIdState, userIdState } from "../../../atoms";

const Login = () => {
  const navigate = useNavigate();
  const { setAuthData } = useContext(AuthContext);
  const setDesignerId = useSetRecoilState(designerIdState);
  const setUserId = useSetRecoilState(userIdState);
  const [lang, setLang] = useState("korean");
  const [formDetails, setFormDetails] = useState({ email: "", password: "" });
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isIncorrect, setIsIncorrect] = useState(false);

  const handleChangeFormDetails = (e) => {
    const { name, value } = e.target;

    setFormDetails({ ...formDetails, [name]: value });
  };

  const handleLogin = async (e) => {
    try {
      const data = await signIn({
        data: {
          email: formDetails.email.trim(),
          pwd: formDetails.password.trim(),
        },
      });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      if (
        data.data.user.designer_id === "" ||
        data.data.user.designer_id === null ||
        data.data.user.designer_id === "0"
      ) {
        setIsIncorrect(true);
        if (data.data.user.id) {
          setUserId(data.data.user.id);
          navigate("/auth/register");
        }
        return;
      }

      // const http = axios.create({
      //   baseURL: config.apiUrl,
      // });

      // http.interceptors.request.use((config) => {
      //   if (data.data.user.access_token) {
      //     config.headers[
      //       "Authorization"
      //     ] = `Bearer ${data.data.user.access_token}`;
      //   }
      //   return config;
      // });

      cookieHelper.setCookie(
        "seller_access_token",
        data.data.user.access_token,
        "1"
      );
      // console.log("Cookie has been set");

      const token = cookieHelper.getCookie();
      // console.log("Cookie retrieved: ", token);

      setAuthData({
        token: data.data.user.access_token,
        loading: false,
        status: true,
      });

      setDesignerId(data.data.user.designer_id);
      setUserId(data.data.user.id);

      setTimeout(() => {
        navigate("/admin/dashboard");
      }, 500);
    } catch (err) {
      setIsIncorrect(true);
      console.log(err);
    }
  };

  const handleClickLogin = () => {
    handleLogin();
  };

  const handleKeyPressLogin = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className="w-full min-h-screen overflow-auto login_page f-center bg-bg-primary">
      <div className="w-full max-w-xs p-4">
        {lang === "english" ? (
          <p className="mb-4 text-base font-medium text-center text-primary">
            Creator Admin
          </p>
        ) : (
          <p className="mb-4 text-base font-medium text-center text-primary">
            작가 어드민
          </p>
        )}
        <div className="mb-12 f-center">
          <Link to="https://www.zaccudaccu.com/">
            <img src={logo} alt="logo" width="280px" />
          </Link>
        </div>
        <div className="login_form">
          <div className="lang f-center">
            <Button
              variant={lang === "korean" ? "contained" : "outlined"}
              color={lang === "korean" ? "primary" : "gray"}
              className={
                "text-base !py-1.5 !px-4 mr-2 " +
                (lang === "korean"
                  ? "border border-transparent"
                  : "!text-primary !border-gray-light")
              }
              onClick={(e) => setLang("korean")}
            >
              Korean
            </Button>
            <Button
              variant={lang === "english" ? "contained" : "outlined"}
              color={lang === "english" ? "primary" : "gray"}
              className={
                "text-base !py-1.5 !px-4 mr-2 " +
                (lang === "english"
                  ? "border border-transparent"
                  : "!text-primary !border-gray-light")
              }
              onClick={(e) => setLang("english")}
            >
              English
            </Button>
          </div>
          <div className="w-full mt-6 inputs">
            <Input
              className="mb-2 !text-font-input text-xs py-2.5 px-6 !border-gray-light"
              variant="outlined"
              color="gray"
              placeholder={
                lang === "korean"
                  ? "이메일을 입력해주세요"
                  : "Enter your email address"
              }
              name="email"
              onChange={handleChangeFormDetails}
              onKeyPress={handleKeyPressLogin}
            />
            <Input
              className="mb-2 !text-font-input text-xs py-2.5 px-6 !border-gray-light"
              variant="outlined"
              color="gray"
              placeholder={
                lang === "korean"
                  ? "비밀번호를 입력해주세요"
                  : "Enter your password"
              }
              name="password"
              type="password"
              onChange={handleChangeFormDetails}
              onKeyPress={handleKeyPressLogin}
            />
            {isIncorrect === true && (
              <p className="mt-2 -mb-3 text-xs text-center text-primary">
                {lang === "korean"
                  ? "이메일/비밀번호를 다시 확인해주세요"
                  : "The email or password is incorrect"}
              </p>
            )}
            <p
              className="mt-6 text-xs text-center underline cursor-pointer text-gray-primary underline-offset-2"
              onClick={(e) => setForgotPassword(true)}
            >
              {lang === "korean"
                ? "이메일/비밀번호를 잊으셨나요?"
                : "Forgot password?"}
            </p>
          </div>

          <div className="inputs w-full max-w-[288px] flex flex-col mt-6">
            <Button
              className="mb-3 !font-medium"
              type="button"
              variant="contained"
              color={
                formDetails?.email?.trim() === "" ||
                formDetails?.email?.indexOf("@") === -1 ||
                formDetails?.password?.trim() === ""
                  ? "grayLight"
                  : "primary"
              }
              hover="off"
              size="lgs"
              disabled={
                formDetails?.email?.trim() === "" ||
                formDetails?.email?.indexOf("@") === -1 ||
                formDetails?.password?.trim() === ""
              }
              onClick={handleClickLogin}
            >
              {lang === "korean" ? "작가 로그인" : "Login"}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              hover="off"
              size="lgs"
              onClick={(e) => navigate("/auth/register")}
              className="!font-medium"
            >
              {lang === "korean" ? "작가로 입점하기" : "Register"}
            </Button>
          </div>
        </div>
      </div>
      {forgotPassword && (
        <ModalBase onClose={(e) => setForgotPassword(false)}>
          <ForgotPassword
            onClose={(e) => setForgotPassword(false)}
            lang={lang}
          />
        </ModalBase>
      )}
    </div>
  );
};

export default Login;
