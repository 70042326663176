import { useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";

import Select from "../Select/Select";
import flags from "../../static/json/flags.json";

const FlagPicker = ({ onChange }) => {
    const [flag, setFlag] = useState({ code: "KR", display: false });

    const handleChangeFlag = (e) => {
        setFlag({ code: e.target.value, display: false });
        if (onChange) onChange(e);
    };

    return (
        <div className="flag_picker">
            <div className="relative px-4 py-1 rounded-full border border-gray-300 f-center z-0">
                <Select onChange={handleChangeFlag} value={flag.code} className={`absolute top-0 left-0 right-0 bottom-0 z-10 ${flag.display ? "" : "opacity-0"}`}>
                    {flags.map((flag) => (
                        <option key={flag.code} value={flag.code}>
                            {flag.emoji} - {flag.name}
                        </option>
                    ))}
                </Select>

                <AiOutlineCaretDown className="text-lg mr-2" />

                <span className="text-3xl">{flags.find((ele) => ele.code === flag.code)?.emoji}</span>
            </div>
        </div>
    );
};

export default FlagPicker;
