import { useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { LogoutModel } from "../../pages";
import cookieHelper from "../../utils/cookieHelper";
import Button from "../Button/Button";
import ModalBase from "../ModalBase/ModalBase";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { designerIdState, userIdState } from "../../atoms";
import { getProfile, getProfileImage } from "../../apis";
import logo from "../../static/img/logo-small.png";

function GetArraow({ direction }) {
  if (direction === "up") direction = true;
  if (direction === "down") direction = false;
  return direction ? (
    <MdKeyboardArrowUp className="text-xl" />
  ) : (
    <MdKeyboardArrowDown className="text-xl" />
  );
}

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const designerId = useRecoilValue(designerIdState);
  const setDesignerId = useSetRecoilState(designerIdState);
  const setUserId = useSetRecoilState(userIdState);
  const [profile, setProfile] = useState({});
  const [profileImage, setProfileImage] = useState("");
  const [logoutModel, setLogoutModel] = useState({ open: false });

  // 유저 프로필 패치
  const fetchProfile = async (params, headers) => {
    try {
      const { data } = await getProfile(params, headers);

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
      setProfile(data.profile);
      if (data.profile.thumb_src && data.profile.thumb_src.length !== 0) {
        fetchProfileImage(data && data.profile.thumb_src);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 이미지데이터 패치, 변환
  const fetchProfileImage = async (src) => {
    try {
      const data = await getProfileImage({ id: src });
      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
      const blob = new Blob([data.data], {
        type: data.headers["content-type"],
      });

      setProfileImage(window.URL.createObjectURL(blob));
    } catch (Err) {
      console.log(Err);
    }
  };

  const [subMenu, setSubMenu] = useState({
    products: { name: "products", open: false },
    sales: { name: "sales", open: false },
    settlement: { name: "settlement", open: false },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const url = location.pathname.split("/");
    if (!url) return;
    if (!subMenu[url]) return;

    if (!url || !subMenu[url]) {
      const updatedSubMenu = {};

      for (const key in subMenu) {
        updatedSubMenu[key] = {
          ...subMenu[key],
          open: false,
        };
      }

      setSubMenu(updatedSubMenu);
      return;
    }
    const updatedSubMenu = {};

    for (const key in subMenu) {
      updatedSubMenu[key] = {
        ...subMenu[key],
        open: key === url ? true : false,
      };
    }

    setSubMenu(updatedSubMenu);
  }, [location.pathname]);

  // 서브메뉴 토글
  const handleClickSubMenuLink = (e, menu) => {
    e.preventDefault();

    const updatedSubMenu = {};
    for (const key in subMenu) {
      updatedSubMenu[key] = {
        ...subMenu[key],
        open: false,
      };
    }

    setSubMenu({
      ...updatedSubMenu,
      [menu.name]: {
        ...menu,
        open: !menu.open,
      },
    });
  };

  const handleClickMenuWithoutSubMenu = () => {
    const updatedSubMenu = {};
    for (const key in subMenu) {
      updatedSubMenu[key] = {
        ...subMenu[key],
        open: false,
      };
    }
    setSubMenu(updatedSubMenu);
  };

  // 로그아웃 모달 핸들
  const handleClickLogout = (e) => {
    setLogoutModel({ open: true });
  };

  // 로그아웃
  const handleLogout = (e, status) => {
    if (status === true) {
      cookieHelper.deleteCookie("seller_access_token");
      setDesignerId(null);
      setUserId(null);
      navigate("/auth/login");
    }

    if (status === false) {
      setLogoutModel({ open: false });
    }
  };

  useEffect(() => {
    fetchProfile({
      id: designerId,
    });
  }, []);

  return (
    <div className="px-6 py-12 transition-all duration-300">
      <div className="mb-6 f-center">
        <Link to="/admin/dashboard">
          <img src={logo} alt="zaccu daccu" style={{ width: "12.422vw" }} />
        </Link>
      </div>
      <div className="flex-col f-center">
        <div className="overflow-hidden bg-gray-100 rounded-full w-36 h-36">
          {profileImage && profileImage.length !== 0 && (
            <img src={profileImage} className="w-full h-full" alt="" />
          )}
        </div>
        <div className="relative group mt-4 px-8 py-2 rounded-tl-2xl rounded-tr-2xl transition-all cursor-pointer hover:bg-white text-white hover:!text-black">
          <h5 className="font-extrabold f-center">
            {profile.brand_name}
            <span className="ml-3 text-sm font-semibold f-center">
              작가님 <MdKeyboardArrowDown className="text-xl" />
            </span>
          </h5>
          <div className="absolute left-0 right-0 flex flex-col items-center justify-center w-full px-8 pt-2 pb-6 transition-all bg-white opacity-0 pointer-events-none top-10 rounded-bl-2xl rounded-br-2xl group-hover:opacity-100 group-hover:pointer-events-auto">
            <Link to="/admin/profile" className="mx-auto mb-4">
              <Button variant="contained" color="primary">
                마이페이지
              </Button>
            </Link>
            <p
              className="text-center text-gray hover:text-primary"
              onClick={handleClickLogout}
            >
              로그아웃
            </p>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
        <Link to="/admin/products/register">
          <Button
            variant="contained"
            color="white"
            hover="off"
            className="!rounded-full w-full py-2.5"
          >
            상품 등록하기
          </Button>
        </Link>
      </div>
      <div className="w-full mt-8">
        <ul>
          <li className="mb-4 dashboard">
            <NavLink
              to="/admin/dashboard"
              className="text-lg font-normal text-white"
              onClick={handleClickMenuWithoutSubMenu}
            >
              전체현황
            </NavLink>
          </li>
          <li className="mb-4 products">
            <NavLink
              className="flex items-center justify-between text-lg font-normal text-white"
              onClick={(e) => handleClickSubMenuLink(e, subMenu.products)}
            >
              상품관리
              <span>{<GetArraow direction={subMenu.products.open} />}</span>
            </NavLink>
            <ul
              className={`sub_menu bg-white p-4 rounded-3xl mt-2 ${
                subMenu.products.open ? "" : "hidden"
              }`}
            >
              <li className="mb-3">
                <NavLink
                  to="/admin/products/list"
                  className={({ isActive }) =>
                    `${isActive ? "text-primary" : "text-gray"}`
                  }
                >
                  상품 조회/수정
                </NavLink>
              </li>
              <li className="mb">
                <NavLink
                  to="/admin/products/register"
                  className={({ isActive }) =>
                    `${isActive ? "text-primary" : "text-gray"}`
                  }
                >
                  상품등록
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="mb-4 sales">
            <NavLink
              to="/admin/sales"
              className="flex items-center justify-between text-lg font-normal text-white"
              onClick={(e) => handleClickSubMenuLink(e, subMenu.sales)}
            >
              판매관리
              <span>{<GetArraow direction={subMenu.sales.open} />}</span>
            </NavLink>
            <ul
              className={`sub_menu bg-white px-4 py-3 rounded-3xl mt-2 ${
                subMenu.sales.open ? "" : "hidden"
              }`}
            >
              <li>
                <NavLink
                  to="/admin/sales/records"
                  className={({ isActive }) =>
                    `${isActive ? "text-primary" : "text-gray"}`
                  }
                >
                  판매기록
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="mb-4 settlement">
            <NavLink
              to="/admin/settlement"
              className="flex items-center justify-between text-lg font-normal text-white"
              onClick={(e) => handleClickSubMenuLink(e, subMenu.settlement)}
            >
              정산관리
              <span>{<GetArraow direction={subMenu.settlement.open} />}</span>
            </NavLink>
            <ul
              className={`sub_menu bg-white px-4 py-3 rounded-3xl mt-2 ${
                subMenu.settlement.open ? "" : "hidden"
              }`}
            >
              <li>
                <NavLink
                  to="/admin/settlement/records"
                  className={({ isActive }) =>
                    `${isActive ? "text-primary" : "text-gray"}`
                  }
                >
                  정산기록
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="mb-4 announcement">
            <NavLink
              to="/admin/announcement"
              className="text-lg font-normal text-white"
              onClick={handleClickMenuWithoutSubMenu}
            >
              공지사항
            </NavLink>
          </li>
        </ul>
      </div>

      {logoutModel.open && (
        <ModalBase onClose={(e) => setLogoutModel({ open: false })}>
          <LogoutModel onLogout={handleLogout} />
        </ModalBase>
      )}
    </div>
  );
};

export default Sidebar;
