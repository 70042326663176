import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  ModalBase,
  RadioButton,
  Select,
  TextArea,
} from "../../components";
import LeaveModal from "../Auth/Leave/LeaveModal";
import { getProfile, getProfileImage } from "../../apis";
import { useRecoilValue } from "recoil";
import { designerIdState } from "../../atoms";
import { Link } from "react-router-dom";

const ProfileArtist = () => {
  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const designerId = useRecoilValue(designerIdState);
  const [profileImage, setProfileImage] = useState("");
  const [user, setUser] = useState({});

  const fetchUserInfo = async () => {
    try {
      const { data } = await getProfile({
        id: designerId,
      });

      setUser(data.profile);

      if (data.profile.thumb_src && data.profile.thumb_src.length !== 0) {
        fetchProfileImage(data && data.profile.thumb_src);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProfileImage = async (src) => {
    try {
      const data = await getProfileImage({ id: src });
      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
      const blob = new Blob([data.data], {
        type: data.headers["content-type"],
      });

      setProfileImage(window.URL.createObjectURL(blob));
    } catch (Err) {
      console.log(Err);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <div className="profile_page">
      <section className="page_header px-4">
        <h2 className="text-base">마이페이지</h2>
      </section>

      <hr className="border-gray-200 my-6" />

      <section className="profile_details px-4">
        <div className="artist_info">
          <div className="flex justify-between items-start">
            <h5 className="text-primary mb-4">작가/브랜드 정보</h5>
            <button
              className="text-gray-400 underline underline-offset-4"
              onClick={() => setOpenLeaveModal(true)}
            >
              작가탈퇴
            </button>
          </div>

          <div className="flex pl-6">
            <p className="w-44">프로필 사진</p>

            <div className="profile_photo">
              <div className="w-32 h-32 rounded-full overflow-hidden bg-gray-200">
                {" "}
                <img src={profileImage} className="object-cover" />
              </div>

              <ul className="text-gray mt-4 text-sm font-normal">
                <li>
                  · 권장 이미지 사이즈
                  <span className="text-primary">500x500px</span>
                </li>
                <li>
                  ·<span className="text-primary">jpg / jpeg / png</span>
                  파일형식
                </li>
              </ul>
            </div>
          </div>

          <div className="flex items-center pl-6 mt-8 w-full">
            <div className="flex items-center w-1/2">
              <p className="w-44 whitespace-nowrap">한글 작가/브랜드명</p>
              <Input
                color="gray"
                variant="outlined"
                className="max-w-xs"
                value={user?.brand_name || ""}
                disabled
              />
            </div>

            <div className="flex items-center ml-6 w-1/2">
              <p className="w-44 whitespace-nowrap">영문 작가/브랜드명</p>
              <Input
                color="gray"
                variant="outlined"
                value={user?.brand_name_en || ""}
                className="max-w-xs"
                disabled
              />
            </div>
          </div>

          <div className="flex items-center pl-6 mt-8 w-full">
            <div className="flex w-full">
              <p className="min-w-[176px] whitespace-nowrap">
                한글 작가/브랜드 설명
              </p>
              <div className="w-full">
                <TextArea
                  color="gray"
                  variant="outlined"
                  value={user?.bio || ""}
                  rows={5}
                  disabled
                  maxLength="1000"
                />
                <p className="text-end text-gray text-xs mt-1">
                  {user?.bio?.length || "0"}/1000
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr className="border-gray-200 my-6" />
        {user?.business_type === "0" ? (
          <IndividualInfo user={user} />
        ) : (
          <BusinessInfo user={user} />
        )}

        <div className="f-center gap-8 mt-16">
          <Link to="/admin/profile-edit">
            <Button variant="contained" color="primary" size="lgs">
              수정하기
            </Button>
          </Link>
        </div>
      </section>

      {openLeaveModal && (
        <ModalBase onClose={(e) => setOpenLeaveModal(false)}>
          <LeaveModal setOpenLeaveModal={setOpenLeaveModal} />
        </ModalBase>
      )}
    </div>
  );
};

export default ProfileArtist;

const IndividualInfo = ({ user }) => {
  return (
    <>
      <div className="sattlement_info">
        <h5 className="text-primary mb-4">정산 정보</h5>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">사업자 형태</p>
          <Select
            defaultValue={user?.business_type}
            className="max-w-xs w-48 bg-gray-50"
            disabled
          >
            <option value="0">개인 프리랜서</option>
            <option value="1">간이사업자</option>
            <option value="2">일반사업자(개인/법인)</option>
          </Select>
          <div className="flex items-center gap-5 ml-6">
            <div className="flex items-center gap-2">
              <RadioButton checked={user?.earn_type === "BUSINESS"} />
              <label>사업소득</label>
            </div>
            <div className="flex items-center gap-2">
              <RadioButton checked={user?.earn_type === "ETC"} />
              <label>기타소득</label>
            </div>
            <p className="text-primary ml-4 underline">정산안내</p>
          </div>
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <div className="flex items-center mr-8">
            <p className="w-36 whitespace-nowrap">성 명</p>
            <Input
              variant="outlined"
              color="gray"
              value={user?.name || ""}
              className="max-w-xs !w-48"
              disabled
            />
          </div>

          <div className="flex items-center">
            <p className="whitespace-nowrap mr-4">주민등록번호</p>
            <div className="relative">
              <div className="flex items-center gap-3">
                <Input
                  variant="outlined"
                  color="gray"
                  value={user.personal_num?.slice(0, 6) || ""}
                  className="max-w-xs w-32"
                  disabled
                />
                <span>-</span>
                <Input
                  variant="outlined"
                  color="gray"
                  value={user?.personal_num?.slice(6) || ""}
                  className="max-w-xs w-32"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center pl-6 w-full mt-8">
          <p className="w-36 whitespace-nowrap">계좌번호</p>

          <div className="flex items-center gap-4 w-full max-w-2xl">
            <Select
              defaultValue="기업은행"
              className="max-w-[180px] w-full bg-gray-50"
              disabled
            >
              <option value="기업은행">기업은행</option>
              <option value="기업은행">기업은행</option>
              <option value="기업은행">기업은행</option>
              <option value="기업은행">기업은행</option>
            </Select>

            <Input
              variant="outlined"
              color="gray"
              value={user?.bank_num || ""}
              className="max-w-[250px]"
              disabled
            />

            <Input
              variant="outlined"
              color="gray"
              value={user?.bank_holder || ""}
              className="max-w-[180px]"
              disabled
            />
          </div>
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">통장사본</p>
          <div className="relative w-80 mr-6">
            {" "}
            <Input
              variant="outlined"
              color="gray"
              value={user?.bank_file_name || ""}
              className="max-w-xs"
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
};

const BusinessInfo = ({ user }) => {
  return (
    <>
      <div className="sattlement_info">
        <h5 className="text-primary mb-4">정산 정보</h5>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">사업자 형태</p>
          <Select
            value={user?.business_type || ""}
            className="max-w-xs w-48 bg-gray-50"
            disabled
          >
            <option value="0">개인 프리랜서</option>
            <option value="1">간이사업자</option>
            <option value="2">일반사업자(개인/법인)</option>
          </Select>
          <p className="text-primary ml-6 underline underline-offset-4">
            정산안내
          </p>
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <div className="flex items-center mr-6">
            <p className="w-36 whitespace-nowrap">상 호 명</p>
            <Input
              variant="outlined"
              color="gray"
              value={user?.company_name || ""}
              className="max-w-xs !w-48"
              disabled
            />
          </div>

          <div className="flex items-center gap-3 w-full">
            <p className="whitespace-nowrap mr-2">대표자명</p>
            <Input
              variant="outlined"
              color="gray"
              value={user?.business_name || ""}
              className="max-w-xs w-48"
              disabled
            />
          </div>
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">사업자등록번호</p>
          <div className="w-1/2 flex items-center">
            <Input
              variant="outlined"
              color="gray"
              value={user?.company_number?.slice(0, 3) || ""}
              className="max-w-xs"
              disabled
            />
            <span className="mx-3">-</span>
            <Input
              variant="outlined"
              color="gray"
              value={user?.company_number?.slice(3, 5) || ""}
              className="max-w-xs"
              disabled
            />
            <span className="mx-3">-</span>
            <Input
              variant="outlined"
              color="gray"
              value={user?.company_number?.slice(5) || ""}
              className="max-w-xs"
              disabled
            />
          </div>
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">사업자등록증</p>
          <Input
            variant="outlined"
            color="gray"
            value={user?.cert_file_name || ""}
            className="max-w-xs mr-6"
            disabled
          />
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">계좌번호</p>
          <Input
            variant="outlined"
            color="gray"
            value={user?.bank_name || ""}
            className="!w-36 mr-3"
            disabled
          />
          <Input
            variant="outlined"
            color="gray"
            value={user?.bank_num || ""}
            className="!w-56 mr-3"
            disabled
          />
          <Input
            variant="outlined"
            color="gray"
            value={user?.bank_holder || ""}
            className="!w-36 mr-3"
            disabled
          />
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">통장사본</p>
          <Input
            variant="outlined"
            color="gray"
            value={user?.bank_file_name || ""}
            className="max-w-xs mr-6"
            disabled
          />
        </div>
      </div>
    </>
  );
};
