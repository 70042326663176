import React, { useEffect, useState, useCallback } from "react";
import { BsPlusLg, BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import Tagify from "@yaireo/tagify/dist/react.tagify";
import { Button, Input, RadioButton, Select, TextArea } from "../../components";
import {
  addProductFile,
  addProductThumb,
  createProduct,
  productFilters,
  uploadForm,
} from "../../apis";

const initData = {
  ftypes: [],
  fmoods: [],
  colors: [],
  colorsId: [],
  cate: "0",
  subcate: "0",
  name: "",
  inst: "",
  price: "",
  priceid: "1",
  tags: [],
};

const AddProduct = () => {
  const [page, setPage] = useState({ loading: false, creating: false });
  const [product, setProduct] = useState({ ...initData });
  const [colors, setColors] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [tnc, setTnc] = useState(false);
  const subcate = {
    sticker: [
      { value: "0", name: "캐릭터 스티커" },
      { value: "1", name: "메모지" },
      { value: "2", name: "데코 스티커" },
      { value: "3", name: "마스킹 테이프" },
    ],
    diary: [
      { value: "10", name: "다이어리/플래너" },
      { value: "11", name: "노트" },
      { value: "12", name: "속지" },
    ],
  };
  const [uploadFormData, setUploadFormData] = useState({
    price_list: [],
    fetched: false,
  });

  const [files, setFiles] = useState([]);
  const [mainThumb, setMainThumb] = useState(null);
  const [thumbs, setThumbs] = useState([]);

  const [productFiltersData, setProductFiltersData] = useState({
    0: "sticker",
    1: "diary",
    sticker: { fetched: false, type: [], mood: [] },
    diary: { fetched: false, type: [], mood: [] },
  });
  const [productTag, setProductTag] = useState("");
  const [hashTag, setHashTag] = useState([]);

  // const handleChangeTag = (e) => {
  //   setProductTag(e.target.value);
  // };

  const settings = {
    maxTags: 10,
    editTags: false,
    originalInputValueFormat: (valuesArr) =>
      valuesArr.map((item) => item.value),
  };

  // useEffect(() => {
  //   const tagArray = productTag.split(" ");
  //   setProduct({ ...product, tags: tagArray });
  // }, [productTag]);

  useEffect(() => {
    setProduct({ ...product, tags: hashTag });
  }, [hashTag]);

  const onHashTagChange = useCallback((e) => {
    // e.detail.value가 쉼표로 구분된 태그 문자열이라고 가정하고, 배열로 분할합니다.
    const tagsArray = e.detail.value.split(",").map((tag) => tag.trim());
    setHashTag(tagsArray);
    // product 태그를 태그 배열로 직접 설정합니다.
    setProduct((prevProduct) => ({ ...prevProduct, tags: tagsArray }));
  }, []);

  const onTagRemove = useCallback((e) => {
    // 제거된 태그를 가져옵니다.
    const removedTagValue = e.detail.data.value;
    setProduct((prevProduct) => {
      // 현재 태그 상태에서 제거된 태그를 필터링합니다.
      const updatedTags = prevProduct.tags
        .split(",")
        .filter((tag) => tag.trim() !== removedTagValue)
        .join(",");
      return {
        ...prevProduct,
        tags: updatedTags,
      };
    });
  }, []);

  // 상품 업로드 폼 패치
  const fetchUploadFormData = async (params, headers) => {
    setPage({ ...page, loading: true });
    try {
      if (uploadFormData.fetched) return;
      const { data } = await uploadForm(params, headers);

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      setUploadFormData({ ...data, fetched: true });
      setColors(data.product_colors);
    } catch (err) {
      console.log(err);
    }
    setPage({ ...page, loading: false });
  };

  // 카테고리에 따라 타입, 분위기 변경
  const fetchProductFiltersData = async (params, headers) => {
    setPage({ ...page, loading: true });
    try {
      let type = "";
      if (params.cate.toString() === "0") {
        if (["0", "1", "2", "3"].includes(params.subcate.toString())) {
          type = "sticker";
        }
      } else if (params.cate.toString() === "1") {
        if (["10", "11", "12"].includes(params.subcate.toString())) {
          type = "diary";
        }
      }

      if (type === "sticker") {
        const { data } = await productFilters(params, headers);
        setProductFiltersData({
          ...productFiltersData,
          sticker: { fetched: true, ...data },
        });
      }

      if (type === "diary") {
        const { data } = await productFilters(params, headers);
        setProductFiltersData({
          ...productFiltersData,
          diary: { fetched: true, ...data },
        });
      }
    } catch (err) {
      console.log(err);
    }
    setPage({ ...page, loading: false });
  };

  useEffect(() => {
    fetchUploadFormData();
  }, []);

  useEffect(() => {
    setProduct({ ...product, ftypes: [], fmoods: [] });
    fetchProductFiltersData({ cate: product.cate, subcate: product.subcate });
  }, [product.cate, product.subcate]);

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    const totalLength = product.inst.length + pasteData.length;

    if (totalLength > 1000) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleChangeData = (e, _name, _value) => {
    let { name, value } = e.target;

    if (_name) name = _name;
    if (_value) value = _value;

    if (name === "name" && value.length >= 100 && product.name.length >= 100)
      return;

    if (
      name === "inst" &&
      value.length >= 1000 &&
      product.inst.length >= 1000
    ) {
      e.preventDefault();
      return;
    }

    if (name === "cate") {
      setFiles([]);
      return setProduct({
        ...product,
        cate: value,
        subcate: value === "0" ? "0" : "10",
      });
    }

    if (name === "subcate") {
      let cateValue = "0";
      if (value === "10" || value === "11" || value === "12") {
        cateValue = "1";
      }
      return setProduct({ ...product, cate: cateValue, subcate: value });
    }

    setProduct({ ...product, [name]: value });
  };

  // 상품 타입 토글
  const handleClickToggleFTypes = (e, ftype) => {
    const i = product.ftypes.indexOf(ftype.id);

    if (i > -1) {
      product.ftypes.splice(i, 1);
    } else {
      product.ftypes.push(ftype.id);
    }

    setProduct({ ...product });
  };
  // 상품 분위기 토글
  const handleClickToggleFMoods = (e, fmood) => {
    const i = product.fmoods.indexOf(fmood.id);

    if (i > -1) {
      product.fmoods.splice(i, 1);
    } else {
      product.fmoods.push(fmood.id);
    }

    setProduct({ ...product });
  };

  // 파일 선택시 추가
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const newFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      if (product.cate === "0" && file.type !== "image/png") {
        alert("PNG 파일만 추가 가능합니다.");
        return false;
      }
      // 다이어리일 때 PDF 파일만 허용
      else if (product.cate === "1" && file.type !== "application/pdf") {
        alert("PDF 파일만 추가 가능합니다.");
        return false;
      }

      newFiles.push(file);
    }

    if (files.length + newFiles.length > 10) {
      alert("파일은 10개까지 추가 가능합니다");
      return;
    }

    if (newFiles.length > 0) {
      setFiles([...files, ...newFiles]);
    }
  };

  const handleChangeMainThumb = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const img = new Image();
      img.src = URL.createObjectURL(selectedFile);

      img.onload = () => {
        if (img.width > 1280 || img.height > 1280) {
          alert("상품 이미지는 1280x1280 이하로 등록해주세요");
        } else {
          setMainThumb(selectedFile);
        }
      };
    }
  };

  const handleChangeSubThumb = (e) => {
    const selectedFiles = e.target.files;

    const newFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];

      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width > 1280 || img.height > 1280) {
          alert("상품 이미지는 1280x1280 이하로 등록해주세요");
        } else {
          newFiles.push(file);
          if (thumbs.length + newFiles.length > 24) {
            alert("최대 25장까지만 업로드 가능합니다.");
            return;
          }

          if (newFiles.length > 0) {
            setThumbs([...thumbs, ...newFiles]);
          }
        }
      };
    }

    // thumbs와 newFiles의 길이를 합해 25장을 넘는지 확인
  };

  // 파일 업로드
  const addFile = async (pid) => {
    try {
      for (const file of files) {
        const formData = new FormData();
        formData.append("pid", pid);
        formData.append("file", file);

        await new Promise((resolve) => setTimeout(resolve, 500));
        const data = await addProductFile({ data: formData });

        if (data.status > 299 || data.status < 200) {
          const message =
            data && data.message ? data.message : "Something went wrong";
          throw new Error(message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addSubThumbs = async (pid) => {
    let thumbnum = 1;
    try {
      for (const thumb of thumbs) {
        const formData = new FormData();
        formData.append("pid", pid);
        formData.append("file", thumb);
        formData.append("thumbnum", thumbnum.toString());

        const data = await addProductThumb({ data: formData });
        if (data.status > 299 || data.status < 200) {
          const message =
            data && data.message ? data.message : "Something went wrong";
          throw new Error(message);
        }
        thumbnum++;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addMainThumb = async (pid) => {
    try {
      const formData = new FormData();
      formData.append("pid", pid);
      formData.append("file", mainThumb);
      formData.append("thumbnum", "0");

      const data = await addProductThumb({ data: formData });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 파일 삭제
  const handleDeleteFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const handleDeleteSubThumbs = (index) => {
    const updatedFiles = thumbs.filter((_, i) => i !== index);
    setThumbs(updatedFiles);
  };

  // 썸네일 변경 인풋
  const handleOpenEditMainthumb = () => {
    document.getElementById("mainThumbChange").click();
  };

  const handleOpenEditSubthumb = (index) => {
    document.getElementById(`subThumbChange-${index}`).click();
  };

  // 인덱스에 맞는 썸네일 변경
  const handleEditSubThumb = (e, index) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const newThumbs = [...thumbs];
      newThumbs[index] = selectedFile;
      setThumbs(newThumbs);
    }
  };

  const handleToggleColor = (e, color) => {
    if (product.colorsId.includes(color.id)) {
      setProduct({
        ...product,
        colorsId: product.colorsId.filter((id) => id !== color.id),
      });
    } else {
      setProduct({ ...product, colorsId: [...product.colorsId, color.id] });
    }
  };

  // 상품 등록
  const handleClickAddProduct = async (e) => {
    const tid = toast.loading("상품 등록중...");
    setPage({ ...page, creating: true });
    try {
      if (!tnc) throw new Error("약관에 동의해주세요");
      if (product.name === "" || !mainThumb || files.length === 0)
        throw new Error("필수항목을 확인해주세요");

      const tagsString = product.tags.join(",");

      const productData = {
        cate: product.cate,
        subcate: product.subcate,
        colors: product.colorsId.join(","),
        filterTypes: product.ftypes.join(","),
        filterMoods: product.fmoods.join(","),
        name: product.name,
        inst: product.inst,
        priceid: isFree === true ? "1" : product.priceid,
        tags: tagsString,
      };

      const formData = new FormData();
      formData.append("cate", productData.cate);
      formData.append("subcate", productData.subcate);
      formData.append("colors", productData.colors);
      formData.append("filterTypes", productData.filterTypes);
      formData.append("filterMoods", productData.filterMoods);
      formData.append("name", productData.name);
      formData.append("inst", productData.inst);
      formData.append("priceid", productData.priceid);
      formData.append("tags", productData.tags);
      formData.append("size", "hor");

      const { data } = await createProduct({ data: formData });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      await addFile(data.productId);
      await addMainThumb(data.productId);
      await addSubThumbs(data.productId);

      toast.success("상품이 등록되었습니다", { id: tid });
      setProduct({ ...initData });
      setHashTag([]);
      // setProductTag("");
      setFiles([]);
      setMainThumb("");
      setThumbs([]);
    } catch (err) {
      toast.error(err.message, { id: tid });
      console.log(err);
    }
    setPage({ ...page, creating: false });
  };

  return (
    <div className="add_product_page">
      <section className="flex items-center justify-between page_header">
        <div className="flex items-center gap-3">
          <h2 className="text-base">상품등록</h2>
          <p className="gap-1 text-primary f-center">
            <span className="block leading-[0.5] mt-2">*</span> 필수항목
          </p>
        </div>

        <Button
          variant="contained"
          color="primary"
          size="sm"
          onClick={handleClickAddProduct}
        >
          등록하기
        </Button>
      </section>

      <hr className="my-6 border-gray-200" />

      <section className="form">
        <form className="pb-8">
          <div className="category">
            <h5 className="mb-4 font-medium text-primary">카테고리</h5>
            <div className="flex items-center gap-8 pl-8">
              <div className="flex items-center w-full">
                <label
                  htmlFor="mainCategory"
                  className="relative w-20 mr-6 font-medium whitespace-nowrap"
                >
                  대 분 류
                  <span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
                </label>
                <Select
                  value={product.cate}
                  className="flex-1"
                  name="cate"
                  onChange={handleChangeData}
                >
                  <option value="0">스티커</option>
                  <option value="1">다이어리</option>
                </Select>
              </div>
              <div className="flex items-center w-full">
                <label
                  htmlFor="mainCategory"
                  className="relative mr-6 font-medium whitespace-nowrap"
                >
                  소 분 류
                  <span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
                </label>
                <Select
                  value={product.subcate}
                  className="!w-full"
                  name="subcate"
                  onChange={handleChangeData}
                >
                  {product.cate === "0"
                    ? subcate.sticker.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))
                    : subcate.diary.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                </Select>
              </div>
            </div>
          </div>

          <hr className="my-8 border-gray-200" />

          <div className="product_details product_name">
            <h5 className="mb-4 font-medium text-primary">상 품 명</h5>
            <div className="flex items-center w-full pl-8">
              <label
                htmlFor="product_name"
                className="relative w-20 mr-6 font-medium whitespace-nowrap"
              >
                한글 상품명
                <span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
              </label>
              <Input
                variant="outlined"
                color="gray"
                placeholder="ex) 자꾸다꾸 스티커"
                name="name"
                value={product.name}
                onChange={handleChangeData}
                maxLength="100"
                className="flex-1 !text-font-input"
              />
              <p className="ml-4 text-xs text-gray">
                {product.name?.length}/100
              </p>
            </div>
          </div>

          <hr className="my-8 border-gray-200" />

          <div className="product_details product_price">
            <div className="flex items-center gap-4 mb-4">
              <h5 className="font-medium text-primary">상품가격</h5>
              <div className="flex items-center gap-2">
                <RadioButton
                  color={isFree ? "primary" : "gray"}
                  checked={isFree}
                  onClick={() => setIsFree(!isFree)}
                />
                <p
                  className={
                    "text-base whitespace-nowrap " +
                    (isFree ? "text-primary" : "text-gray")
                  }
                >
                  무료 상품일 경우 선택
                </p>
              </div>
            </div>

            <div className="flex items-center">
              <div className="flex items-center w-full pl-8 md:w-1/2">
                <label
                  htmlFor="product_name"
                  className="relative mr-6 font-medium whitespace-nowrap"
                >
                  판 매 가
                  <span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
                </label>
                <Select
                  className="w-full max-w-sm disabled:bg-gray-200"
                  value={product.priceid}
                  name="priceid"
                  onChange={handleChangeData}
                  disabled={isFree}
                >
                  {uploadFormData.price_list?.map((pricing, i) => (
                    <option key={pricing.id || i} value={pricing.id}>
                      {pricing.price}
                    </option>
                  ))}
                </Select>
                <p className="mx-4">원</p>
              </div>
            </div>
          </div>

          <hr className="my-8 border-gray-200" />

          <div className="product_details file_registration">
            <div className="flex items-center gap-1">
              <h5 className="mr-2 font-medium text-primary">상품등록</h5>
              <Button variant="contained" color="primary" size="sm">
                등록 가이드
              </Button>
            </div>

            <div className="flex pl-8 mt-6">
              <h5 className="relative h-full">
                파일등록
                <span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
              </h5>

              <div className="ml-8">
                <label
                  htmlFor="selectedFile"
                  className="px-3 py-1 border rounded-full cursor-pointer text-gray border-gray hover:border-primary-dark hover:bg-primary-dark hover:text-white"
                >
                  파일 선택
                </label>
                <input
                  key={product.cate}
                  type="file"
                  id="selectedFile"
                  onChange={handleFileChange}
                  className="hidden"
                  multiple
                  accept={
                    product.cate === "0"
                      ? ".png, image/png"
                      : ".pdf, application/pdf"
                  }
                />

                <div className="flex flex-col gap-2 mt-4">
                  {files && files.length !== 0
                    ? files.map((file, i) => (
                        <div className="flex items-center gap-3" key={i}>
                          <Button
                            variant="outlined"
                            color="gray"
                            size="sm"
                            className="w-full max-w-xs text-left text-gray-700"
                            hover="off"
                          >
                            {file.name}
                          </Button>
                          <Button
                            variant="contained"
                            color="gray"
                            size="sm"
                            onClick={() => handleDeleteFile(i)}
                            icon
                          >
                            <BsTrash />
                          </Button>
                        </div>
                      ))
                    : null}
                </div>

                <div className="mt-4 text-xs list-none text-gray">
                  {product.cate === "0" ? (
                    <>
                      <li className="text-primary">· png 파일형식 필수 </li>
                      <li>
                        · <span className="text-primary">최대 10개</span> 등록
                        가능
                      </li>
                      <li>· 최대 3000px(300dpi)</li>
                    </>
                  ) : (
                    <>
                      <li className="text-primary">· pdf 파일형식 필수 </li>
                      <li>
                        · <span className="text-primary">최대 10개</span> 등록
                        가능
                      </li>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <hr className="my-8 border-gray-200" />

          <div className="product_details">
            <div className="flex items-center gap-1">
              <h5 className="mr-2 font-medium text-primary">상품정보</h5>
              <Button variant="contained" color="primary" size="sm">
                등록 가이드
              </Button>
            </div>

            <div className="flex pl-8 mt-6">
              <h5 className="relative flex-shrink-0 h-full">
                상품 이미지
                <span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
              </h5>

              <div className="ml-8">
                <div className="flex flex-wrap">
                  {mainThumb ? (
                    <div className="relative w-32 h-32 mb-2 mr-3 overflow-hidden group lg:mr-10">
                      {" "}
                      <div
                        role="button"
                        className="w-32 h-32 overflow-hidden border cursor-pointer border-primary rounded-3xl f-center"
                      >
                        <img src={URL.createObjectURL(mainThumb)} alt="" />
                      </div>
                      <div className="w-32 h-32 justify-end px-2 py-2 rounded-3xl absolute top-0 bottom-0 left-0 right-0 bg-[rgba(225,225,225,0.3)] z-20 hidden group-hover:flex">
                        <div>
                          {" "}
                          <Button
                            variant="contained"
                            color="primary"
                            icon
                            size="sm"
                            onClick={handleOpenEditMainthumb}
                          >
                            <FiEdit />
                          </Button>
                          <input
                            type="file"
                            accept=".jpg, .png, image/jpeg, image/png"
                            className="hidden"
                            id="mainThumbChange"
                            onChange={handleChangeMainThumb}
                          />
                        </div>
                        <Button
                          variant="contained"
                          color="primary"
                          icon
                          size="sm"
                          className="ml-2"
                          onClick={() => setMainThumb("")}
                        >
                          <BsTrash />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <label htmlFor="mainThumb">
                        <div
                          role="button"
                          className="w-32 h-32 mb-2 mr-3 border cursor-pointer border-primary rounded-3xl f-center lg:mr-10"
                        >
                          <BsPlusLg className="text-4xl text-primary font-fullbold" />
                        </div>
                      </label>
                      <input
                        type="file"
                        accept=".jpg, .png, image/jpeg, image/png"
                        id="mainThumb"
                        className="hidden"
                        onChange={handleChangeMainThumb}
                      />
                    </>
                  )}
                  {thumbs && thumbs.length !== 0
                    ? thumbs.map((thumb, i) => (
                        <div
                          className="relative w-32 h-32 mb-2 mr-3 group"
                          key={i}
                        >
                          <div
                            role="button"
                            className="w-32 h-32 mb-2 overflow-hidden border cursor-pointer border-gray rounded-3xl f-center"
                            aria-disabled="true"
                          >
                            <img src={URL.createObjectURL(thumb)} alt="" />
                          </div>
                          <div className="w-32 h-32 justify-end px-2 py-2 rounded-3xl absolute top-0 bottom-0 left-0 right-0 bg-[rgba(225,225,225,0.3)] z-20 hidden group-hover:flex">
                            <div>
                              {" "}
                              <Button
                                variant="contained"
                                color="primary"
                                icon
                                size="sm"
                                onClick={() => handleOpenEditSubthumb(i)}
                              >
                                <FiEdit />
                              </Button>
                              <input
                                type="file"
                                className="hidden"
                                accept=".jpg, .png, image/jpeg, image/png"
                                id={`subThumbChange-${i}`}
                                onChange={(e) => handleEditSubThumb(e, i)}
                              />
                            </div>

                            <Button
                              variant="contained"
                              color="primary"
                              icon
                              size="sm"
                              className="ml-2"
                              onClick={() => handleDeleteSubThumbs(i)}
                            >
                              <BsTrash />
                            </Button>
                          </div>
                        </div>
                      ))
                    : null}
                  <label htmlFor="thumb">
                    <div
                      role="button"
                      className="w-32 h-32 border cursor-pointer border-gray rounded-3xl f-center"
                      aria-disabled="true"
                    >
                      <BsPlusLg className="text-4xl text-gray font-fullbold" />
                    </div>
                  </label>
                  <input
                    type="file"
                    id="thumb"
                    accept=".jpg, .png, image/jpeg, image/png"
                    className="hidden"
                    onChange={handleChangeSubThumb}
                    multiple
                  />{" "}
                </div>

                <div className="mt-4 text-xs list-none text-gray">
                  <li className="text-primary">
                    · 대표 이미지를 첫번째로 등록{" "}
                    <span className="text-gray">(대표 이미지는</span> 필수
                    <span className="text-gray">에요)!</span>{" "}
                  </li>
                  <li>
                    · 최대크기 <span className="text-primary">1280x1280px</span>
                  </li>
                  <li>
                    · <span className="text-primary">최대 25장</span> 등록 가능
                  </li>
                  <li>
                    · <span className="text-primary">jpg / jpeg / png</span>{" "}
                    파일형식
                  </li>
                </div>
              </div>
            </div>

            <div className="flex pl-8 mt-6">
              <h5 className="h-full">한글 상품설명</h5>

              <div className="w-full max-w-2xl ml-8">
                <TextArea
                  variant="outlined"
                  color="gray"
                  rows={5}
                  name="inst"
                  value={product.inst}
                  onPaste={handlePaste}
                  onChange={handleChangeData}
                  className="!text-font-input"
                />
                <p className="mt-1 font-normal text-end text-gray">
                  {product.inst.length}/1000
                </p>
              </div>
            </div>

            <div className="flex pl-8 mt-6">
              <h5 className="h-full">상품필터</h5>

              <div className="w-full max-w-3xl ml-8">
                <div className="filters_type">
                  <h6 className="tracking-[10px]">분 류</h6>

                  <div className="flex flex-wrap items-center gap-4 px-4 py-3 mt-4 border border-gray-300 rounded-2xl md:px-6">
                    {productFiltersData[
                      productFiltersData[product.cate]
                    ].type.map((type, i) => {
                      const bool = product.ftypes.includes(type.id);
                      return (
                        <Button
                          key={type.id || i}
                          variant={bool ? "contained" : "outlined"}
                          color={bool ? "primary" : "gray"}
                          size="sm"
                          onClick={(e) => handleClickToggleFTypes(e, type)}
                          className={bool && "border border-transparent"}
                        >
                          {type.name}
                        </Button>
                      );
                    })}
                  </div>
                </div>

                <div className="mt-8 filters_mood">
                  <h6>분위기</h6>

                  <div className="flex flex-wrap items-center gap-4 px-4 py-3 mt-4 border border-gray-300 rounded-2xl md:px-6">
                    {productFiltersData[
                      productFiltersData[product.cate]
                    ].mood.map((mood, i) => {
                      const bool = product.fmoods.indexOf(mood.id) > -1;
                      return (
                        <Button
                          key={mood.id || i}
                          variant={bool ? "contained" : "outlined"}
                          color={bool ? "primary" : "gray"}
                          size="sm"
                          className={bool && "border border-transparent"}
                          onClick={(e) => handleClickToggleFMoods(e, mood)}
                        >
                          {mood.name}
                        </Button>
                      );
                    })}
                  </div>
                </div>

                <div className="mt-8 color">
                  <h6>색 상</h6>

                  <div className="flex flex-wrap items-center gap-4 px-4 py-3 mt-4 border border-gray-300 rounded-2xl md:px-6">
                    {colors.map((color, i) => (
                      <div
                        key={`color_${i}`}
                        className={
                          "h-7 w-7 f-center rounded-full " +
                          (product.colorsId.includes(color.id)
                            ? " border-2 border-red-500"
                            : " border border-gray-200")
                        }
                        style={{ background: "#" + color.rgb_code }}
                        onClick={(e) => handleToggleColor(e, color)}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex pl-8 mt-12">
              <h5 className="h-full md:mt-2">상품태그</h5>

              <div className="w-full max-w-3xl ml-8 tags">
                {/* <Input
                  variant="outlined"
                  color="gray"
                  placeholder="#어울리는 태그를 입력해주세요 (최대10개)"
                  name="tags"
                  value={productTag}
                  onChange={handleChangeTag}
                /> */}
                <Tagify
                  settings={settings}
                  value={hashTag}
                  onChange={onHashTagChange}
                  onRemove={onTagRemove}
                  placeholder="#어울리는 태그를 입력해주세요 (최대10개)"
                />
                <div className="flex items-start mt-4">
                  <ul className="mr-6">
                    <li className="font-normal text-primary"> · 엔터로 구분</li>
                    {/* <li className="font-normal text-gray">· ‘#’은 자동입력</li> */}
                  </ul>
                  {/* <ul className="flex space-x-2">
                    {product?.tags?.map((tag, i) => (
                      <li
                        key={i}
                        className="px-4 py-1 text-sm font-normal bg-gray-100 rounded-full"
                      >
                        #{tag}
                      </li>
                    ))}
                  </ul> */}
                </div>
              </div>
            </div>
          </div>

          <hr className="my-8 border-gray-200" />

          <div className="product_details">
            <div className="flex gap-4 pl-8">
              <h5 className="font-medium text-primary">필수 확인사항</h5>

              <div className="ml-4">
                <div className="flex items-center gap-2">
                  <RadioButton checked={tnc} onClick={(e) => setTnc(!tnc)} />
                  <p className="text-base whitespace-nowrap">
                    모든 약관에 동의합니다.
                  </p>
                </div>

                <ul className="mt-3 text-gray">
                  <li>
                    (필수) 본 상품은 타인의 저작권을 모방하거나 침해하지
                    않았음을 진술하고 보증합니다.
                  </li>
                  <li>
                    (필수) 저작권 분쟁 발생 시 창작일 증빙이 요청될 수 있음을
                    인지하였습니다.
                  </li>
                  <li>
                    (필수) 자꾸다꾸에 등록하는 상품의 저작권은 작가 본인에게
                    있음을 확인합니다.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="gap-6 mt-16 submit_buttons f-center">
            <Button
              variant="contained"
              color="primary"
              size="lg"
              onClick={handleClickAddProduct}
              disabled={page.creating}
            >
              등록하기
            </Button>

            <Link to="/admin/products/list">
              {" "}
              <Button variant="outlined" color="primary" size="lg">
                취 소
              </Button>
            </Link>
          </div>
        </form>
      </section>
    </div>
  );
};

export default AddProduct;
