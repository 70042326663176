import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  ModalBase,
  RadioButton,
  Select,
  TextArea,
} from "../../components";
import LeaveModal from "../Auth/Leave/LeaveModal";
import {
  getProfile,
  getProfileImage,
  updateBusiness,
  updateDesigner,
  updateProfileImage,
} from "../../apis";
import { useRecoilValue } from "recoil";
import { designerIdState } from "../../atoms";
import { Link, useNavigate } from "react-router-dom";
import { bankList } from "../../utils/dataList";
import { toast } from "react-hot-toast";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";

const EditArtist = () => {
  const navigate = useNavigate();
  const [openLeaveModal, setOpenLeaveModal] = useState(false);
  const designerId = useRecoilValue(designerIdState);
  const [user, setUser] = useState({});
  const [profileImage, setProfileImage] = useState("");
  const [newProfileImage, setNewProfileImage] = useState(new File([], ""));
  const [certFile, setCertFile] = useState(null);
  const [bankFile, setBankFile] = useState(null);
  const [certNum, setCertNum] = useState({
    first: "",
    second: "",
    third: "",
  });
  const [personalNum, setPersonalNum] = useState({
    first: "",
    second: "",
  });

  const handleChangeCertNum = (event) => {
    const { name, value } = event.target;

    setCertNum({
      ...certNum,
      [name]: value,
    });
  };

  const fetchUserInfo = async () => {
    try {
      const { data } = await getProfile({
        id: designerId,
      });
      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      setUser(data.profile);

      if (
        data.profile.company_number &&
        data.profile.company_number.length === 10
      ) {
        setCertNum({
          first: data.profile.company_number.slice(0, 3),
          second: data.profile.company_number.slice(3, 5),
          third: data.profile.company_number.slice(5),
        });
      }

      if (data?.profile?.personal_num && data?.profile?.personal_num !== "") {
        setPersonalNum({
          first: data?.profile?.personal_num?.slice(0, 6),
          second: data?.profile?.personal_num?.slice(6),
        });
      }

      if (data.profile.thumb_src && data.profile.thumb_src.length !== 0) {
        fetchProfileImage(data && data.profile.thumb_src);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProfileImage = async (src) => {
    try {
      const data = await getProfileImage({ id: src });
      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
      const blob = new Blob([data.data], {
        type: data.headers["content-type"],
      });

      setProfileImage(window.URL.createObjectURL(blob));
    } catch (Err) {
      console.log(Err);
    }
  };

  const handleChangeData = (e, _name, _value) => {
    let { name, value } = e.target;

    setUser({ ...user, [name]: value });
  };

  const handleChangeEarnType = (value) => {
    setUser({ ...user, earn_type: value });
  };

  const handleChangePersonalNum = (e) => {
    const { name, value } = e.target;
    setPersonalNum({ ...personalNum, [name]: value });
  };

  const handleChangeBankFile = (e) => {
    const file = e.target.files[0];
    if (file.type !== "application/pdf") {
      toast.error("pdf 파일만 추가 가능합니다.");
    } else {
      setBankFile(file);
    }
  };

  const handleChangeCertFile = (e) => {
    const file = e.target.files[0];
    if (file.type !== "application/pdf") {
      toast.error("pdf 파일만 추가 가능합니다.");
    } else {
      setCertFile(file);
    }
  };

  const openProfileImageInput = () => {
    document.getElementById("profileImage").click();
  };

  const handleChangeProfileImage = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setNewProfileImage(selectedFile);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("id", user?.bs_users_id);
      formData.append("name", user?.name);
      formData.append("brand_name", user?.brand_name);
      formData.append("brand_name_en", user?.brand_name_en);
      formData.append("email", user?.email);
      formData.append("phone", user?.phone);
      formData.append("web_addr", user?.web_addr);
      formData.append("bio", user?.bio);

      const data = await updateDesigner({ data: formData });
      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateSettle = async () => {
    try {
      const formData = new FormData();
      formData.append("userId", user?.bs_users_id);
      formData.append("bank_holder", user?.bank_holder);
      formData.append("personal_num", personalNum.first + personalNum.second);
      formData.append("bank_num", user?.bank_num);
      formData.append("bank_name", user?.bank_name);
      formData.append("company_name", user?.company_name);
      formData.append(
        "company_number",
        certNum.first + certNum.second + certNum.third
      );
      formData.append("business_name0", user?.name);
      formData.append("business_name1", user?.business_name);
      formData.append("business_type", user?.business_type);
      formData.append("earnType", user?.earn_type);
      if (bankFile) {
        formData.append("bankFile", bankFile);
      }
      if (certFile) {
        formData.append("certFile", certFile);
      }

      const data = await updateBusiness({ data: formData });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateProfileImage = async () => {
    try {
      const formData = new FormData();
      formData.append("userId", user?.bs_users_id);
      formData.append("imgdata", newProfileImage);
      const data = updateProfileImage({ data: formData });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitEdit = async () => {
    try {
      await handleUpdateProfile();
      await handleUpdateSettle();
      if (newProfileImage && newProfileImage.size > 0) {
        await handleUpdateProfileImage();
      }

      toast.success("수정이 완료되었습니다");
      navigate("/admin/profile");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <div className="profile_page">
      <section className="page_header px-4">
        <h2 className="text-base">마이페이지</h2>
      </section>

      <hr className="border-gray-200 my-6" />

      <section className="profile_details px-4">
        <div className="artist_info">
          <div className="flex justify-between items-start">
            <h5 className="text-primary mb-4">작가/브랜드 정보</h5>
            <button
              className="text-gray-400 underline underline-offset-4"
              onClick={() => setOpenLeaveModal(true)}
            >
              작가탈퇴
            </button>
          </div>

          <div className="flex pl-6">
            <p className="w-44">프로필 사진</p>

            <div className="profile_photo">
              <div className="relative group">
                {" "}
                <div className="w-32 h-32 rounded-full overflow-hidden bg-gray-200">
                  {" "}
                  <img
                    src={
                      newProfileImage && newProfileImage.size > 0
                        ? URL.createObjectURL(newProfileImage)
                        : profileImage
                    }
                    className="object-cover"
                  />
                </div>
                <div className="w-32 h-32 absolute rounded-full top-0 bottom-0 left-0 right-0 f-center bg-[rgba(225,225,225,0.3)] z-20 hidden group-hover:flex">
                  <Button
                    variant="contained"
                    color="primary"
                    icon
                    size="sm"
                    onClick={openProfileImageInput}
                  >
                    <FiEdit />
                  </Button>
                  <input
                    type="file"
                    className="hidden"
                    id="profileImage"
                    onChange={handleChangeProfileImage}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    icon
                    size="sm"
                    className="ml-2"
                    onClick={() => setNewProfileImage(null)}
                  >
                    <BsTrash />
                  </Button>
                </div>
              </div>

              <ul className="text-gray mt-4 text-sm font-normal">
                <li>
                  · 권장 이미지 사이즈
                  <span className="text-primary">500x500px</span>
                </li>
                <li>
                  ·<span className="text-primary">jpg / jpeg / png</span>
                  파일형식
                </li>
              </ul>
            </div>
          </div>

          <div className="flex items-center pl-6 mt-8 w-full">
            <div className="flex items-center w-1/2">
              <p className="w-44 whitespace-nowrap">한글 작가/브랜드명</p>
              <Input
                color="gray"
                variant="outlined"
                className="max-w-xs !text-font-input"
                value={user?.brand_name}
                name="brand_name"
                onChange={handleChangeData}
              />
            </div>

            <div className="flex items-center ml-6 w-1/2">
              <p className="w-44 whitespace-nowrap">영문 작가/브랜드명</p>
              <Input
                color="gray"
                variant="outlined"
                value={user?.brand_name_en}
                name="brand_name_en"
                onChange={handleChangeData}
                className="max-w-xs !text-font-input"
              />
            </div>
          </div>

          <div className="flex items-center pl-6 mt-8 w-full">
            <div className="flex w-full">
              <p className="min-w-[176px] whitespace-nowrap">
                한글 작가/브랜드 설명
              </p>
              <div className="w-full">
                <TextArea
                  color="gray"
                  variant="outlined"
                  value={user?.bio}
                  name="bio"
                  onChange={handleChangeData}
                  rows={5}
                  maxLength="1000"
                  className="!text-font-input"
                />
                <p className="text-end text-gray text-xs mt-1">
                  {user?.bio?.length}/1000
                </p>
              </div>
            </div>
          </div>
        </div>

        <hr className="border-gray-200 my-6" />
        {user?.business_type === "0" ? (
          <IndividualInfo
            user={user}
            handleChangeData={handleChangeData}
            handleChangeEarnType={handleChangeEarnType}
            bankFile={bankFile}
            handleChangeBankFile={handleChangeBankFile}
            handleChangePersonalNum={handleChangePersonalNum}
            personalNum={personalNum}
          />
        ) : (
          <BusinessInfo
            user={user}
            handleChangeData={handleChangeData}
            certFile={certFile}
            bankFile={bankFile}
            certNum={certNum}
            handleChangeCertNum={handleChangeCertNum}
            handleChangeBankFile={handleChangeBankFile}
            handleChangeCertFile={handleChangeCertFile}
          />
        )}

        <div className="f-center gap-8 mt-16">
          <Button
            variant="contained"
            color="primary"
            size="lgs"
            onClick={handleSubmitEdit}
          >
            수정하기
          </Button>
        </div>
      </section>

      {openLeaveModal && (
        <ModalBase onClose={() => setOpenLeaveModal(false)}>
          <LeaveModal setOpenLeaveModal={setOpenLeaveModal} />
        </ModalBase>
      )}
    </div>
  );
};

export default EditArtist;

const IndividualInfo = ({
  user,
  handleChangeData,
  handleChangeEarnType,
  bankFile,
  handleChangeBankFile,
  personalNum,
  handleChangePersonalNum,
}) => {
  return (
    <>
      <div className="sattlement_info">
        <h5 className="text-primary mb-4">정산 정보</h5>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">사업자 형태</p>
          <Select
            value={user?.business_type}
            name="business_type"
            onChange={handleChangeData}
            className="max-w-xs w-48 !text-font-input"
          >
            <option value="0">개인 프리랜서</option>
            <option value="1">간이사업자</option>
            <option value="2">일반사업자(개인/법인)</option>
          </Select>
          <div className="flex items-center gap-5 ml-6">
            <div className="flex items-center gap-2">
              <RadioButton
                checked={user?.earn_type === "BUSINESS"}
                onClick={() => handleChangeEarnType("BUSINESS")}
              />
              <label>사업소득</label>
            </div>
            <div className="flex items-center gap-2">
              <RadioButton
                checked={user?.earn_type === "ETC"}
                onClick={() => handleChangeEarnType("ETC")}
              />
              <label>기타소득</label>
            </div>
            <p className="text-primary ml-4 underline">정산안내</p>
          </div>
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <div className="flex items-center mr-8">
            <p className="w-36 whitespace-nowrap">성 명</p>
            <Input
              variant="outlined"
              color="gray"
              value={user?.name}
              name="name"
              onChange={handleChangeData}
              className="max-w-xs !w-48 !text-font-input"
            />
          </div>

          <div className="flex items-center">
            <p className="whitespace-nowrap mr-4">주민등록번호</p>
            <div className="relative">
              <div className="flex items-center gap-3">
                <Input
                  variant="outlined"
                  color="gray"
                  name="first"
                  value={personalNum.first}
                  onChange={handleChangePersonalNum}
                  className="max-w-xs w-32 !text-font-input"
                />
                <span>-</span>
                <Input
                  variant="outlined"
                  color="gray"
                  name="second"
                  value={personalNum.second}
                  onChange={handleChangePersonalNum}
                  className="max-w-xs w-32 !text-font-input"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center pl-6 w-full mt-8">
          <p className="w-36 whitespace-nowrap">계좌번호</p>

          <div className="flex items-center gap-4 w-full max-w-2xl">
            <Select
              value={user?.bank_name}
              className="max-w-[180px] w-full !text-font-input"
              name="bank_name"
              onChange={handleChangeData}
            >
              {bankList?.map((bank) => (
                <option value={bank.value} key={bank.id}>
                  {bank.value}
                </option>
              ))}
            </Select>

            <Input
              variant="outlined"
              color="gray"
              name="bank_num"
              value={user?.bank_num}
              onChange={handleChangeData}
              className="max-w-[250px] !text-font-input"
            />

            <Input
              variant="outlined"
              color="gray"
              value={user?.bank_holder}
              name="bank_holder"
              onChange={handleChangeData}
              className="max-w-[180px] !text-font-input"
            />
          </div>
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">통장사본</p>
          <p className="flex rounded-full items-center w-80 h-[2.375rem] px-4 py-2 text-sm border !text-font-input border-gray-300 mr-5">
            {bankFile !== null ? bankFile.name : user.bank_file_name}
          </p>
          <div>
            <label
              htmlFor="bankfile"
              className="bg-primary text-white cursor-pointer text-sm font-semibold px-4 py-2 rounded-full"
            >
              변경하기
            </label>
            <input
              type="file"
              id="bankfile"
              className="hidden"
              onChange={handleChangeBankFile}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const BusinessInfo = ({
  user,
  handleChangeData,
  bankFile,
  certFile,
  handleChangeBankFile,
  handleChangeCertFile,
  handleChangeCertNum,
  certNum,
}) => {
  return (
    <>
      <div className="sattlement_info">
        <h5 className="text-primary mb-4">정산 정보</h5>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">사업자 형태</p>
          <Select
            value={user?.business_type}
            name="business_type"
            onChange={handleChangeData}
            className="max-w-xs w-48 bg-gray-50 !text-font-input"
          >
            <option value="0">개인 프리랜서</option>
            <option value="1">간이사업자</option>
            <option value="2">일반사업자(개인/법인)</option>
          </Select>
          <p className="text-primary ml-6 underline underline-offset-4">
            정산안내
          </p>
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <div className="flex items-center mr-6">
            <p className="w-36 whitespace-nowrap">상 호 명</p>
            <Input
              variant="outlined"
              color="gray"
              value={user?.company_name}
              name="company_name"
              onChange={handleChangeData}
              className="max-w-xs !w-48 !text-font-input"
            />
          </div>

          <div className="flex items-center gap-3 w-full">
            <p className="whitespace-nowrap mr-2">대표자명</p>
            <Input
              variant="outlined"
              color="gray"
              value={user?.business_name}
              name="business_name"
              onChange={handleChangeData}
              className="max-w-xs w-48 !text-font-input"
            />
          </div>
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">사업자등록번호</p>
          <div className="w-1/2 flex items-center">
            <Input
              variant="outlined"
              color="gray"
              name="first"
              value={certNum.first}
              onChange={handleChangeCertNum}
              className="max-w-xs !text-font-input"
            />
            <span className="mx-3">-</span>
            <Input
              variant="outlined"
              color="gray"
              name="second"
              value={certNum.second}
              onChange={handleChangeCertNum}
              className="max-w-xs !text-font-input"
            />
            <span className="mx-3">-</span>
            <Input
              variant="outlined"
              color="gray"
              name="third"
              value={certNum.third}
              onChange={handleChangeCertNum}
              className="max-w-xs !text-font-input"
            />
          </div>
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">사업자등록증</p>
          <p className="flex rounded-full items-center w-80 h-[2.375rem] px-4 py-2 text-sm border !text-font-input border-gray-300 mr-5">
            {certFile !== null ? certFile.name : user.cert_file_name}
          </p>
          <div>
            <label
              htmlFor="certFile"
              className="bg-primary text-white cursor-pointer text-sm font-semibold px-4 py-2 rounded-full"
            >
              변경하기
            </label>
            <input
              type="file"
              id="certFile"
              className="hidden"
              onChange={handleChangeCertFile}
            />
          </div>
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">계좌번호</p>
          <Select
            className="!text-font-input"
            onChange={handleChangeData}
            name="bank_name"
            value={user.bank_name}
          >
            {bankList.map((bank) => (
              <option key={bank.id} value={bank.value}>
                {bank.value}
              </option>
            ))}
          </Select>
          <Input
            variant="outlined"
            color="gray"
            name="bank_num"
            value={user?.bank_num}
            onChange={handleChangeData}
            className="!w-56 mr-3 ml-3 !text-font-input"
          />
          <Input
            variant="outlined"
            color="gray"
            name="bank_holder"
            value={user?.bank_holder}
            onChange={handleChangeData}
            className="!w-36 mr-3 !text-font-input"
          />
        </div>

        <div className="flex items-center pl-6 mt-8 w-full">
          <p className="w-36 whitespace-nowrap">통장사본</p>
          <p className="flex rounded-full items-center w-80 h-[2.375rem] px-4 py-2 text-sm border !text-font-input border-gray-300 mr-5">
            {bankFile !== null ? bankFile.name : user.bank_file_name}
          </p>
          <div>
            <label
              htmlFor="bankfile"
              className="bg-primary text-white cursor-pointer text-sm font-semibold px-4 py-2 rounded-full"
            >
              변경하기
            </label>
            <input
              type="file"
              id="bankfile"
              className="hidden"
              onChange={handleChangeBankFile}
            />
          </div>
        </div>
      </div>
    </>
  );
};
