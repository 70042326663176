import { useEffect, useState } from "react";
import { getThumbnail, getThumbnailFull } from "../apis";

export default function ProductThumbnail({ url, type = "default" }) {
  const [thumbnail, setThumbnail] = useState("");

  const getThumbnails = async () => {
    if (url === "" || url === undefined || url === null) return;
    try {
      if (type === "default") {
        const data = await getThumbnail({ is: url });
        if (data.status > 299 || data.status < 200) {
          const message =
            data && data.message ? data.message : "Something went wrong";
          throw new Error(message);
        }
        const blob = new Blob([data.data], {
          type: data.headers["content-type"],
        });
        setThumbnail(window.URL.createObjectURL(blob));
      }

      if (type === "full") {
        const data = await getThumbnailFull({ url });
        if (data.status > 299 || data.status < 200) {
          const message =
            data && data.message ? data.message : "Something went wrong";
          throw new Error(message);
        }
        const blob = new Blob([data.data], {
          type: data.headers["content-type"],
        });
        setThumbnail(window.URL.createObjectURL(blob));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getThumbnails();
  }, [url]);

  return (
    <div className="product_image w-full h-full overflow-hidden">
      <img src={thumbnail} alt="" className="object-cover w-full h-full" />
    </div>
  );
}
