export const keywordList = [
  { id: 1, name: "캐릭터", type: 0, cate: 0, subcate: 0 },
  { id: 2, name: "일상", type: 0, cate: 0, subcate: 0 },
  { id: 3, name: "감정", type: 0, cate: 0, subcate: 0 },
  { id: 4, name: "자연", type: 0, cate: 0, subcate: 0 },
  { id: 5, name: "음식", type: 0, cate: 0, subcate: 0 },
  { id: 6, name: "패키지", type: 0, cate: 0, subcate: 0 },
  { id: 7, name: "귀여운", type: 1, cate: 0, subcate: 0 },
  { id: 8, name: "감성적인", type: 1, cate: 0, subcate: 0 },
  { id: 9, name: "심플한", type: 1, cate: 0, subcate: 0 },
  { id: 10, name: "빈티지", type: 1, cate: 0, subcate: 0 },
  { id: 11, name: "특별한", type: 1, cate: 0, subcate: 0 },
  { id: 12, name: "일반", type: 0, cate: 0, subcate: 1 },
  { id: 13, name: "직장", type: 0, cate: 0, subcate: 1 },
  { id: 14, name: "스터디", type: 0, cate: 0, subcate: 1 },
  { id: 15, name: "취미", type: 0, cate: 0, subcate: 1 },
  { id: 16, name: "트래커/챌린지", type: 0, cate: 0, subcate: 1 },
  { id: 17, name: "체크리스트", type: 0, cate: 0, subcate: 1 },
  { id: 18, name: "패키지", type: 0, cate: 0, subcate: 1 },
  { id: 19, name: "귀여운", type: 1, cate: 0, subcate: 1 },
  { id: 20, name: "감성적인", type: 1, cate: 0, subcate: 1 },
  { id: 21, name: "심플한", type: 1, cate: 0, subcate: 1 },
  { id: 22, name: "빈티지", type: 1, cate: 0, subcate: 1 },
  { id: 23, name: "특별한", type: 1, cate: 0, subcate: 1 },
  { id: 24, name: "귀여운", type: 1, cate: 0, subcate: 2 },
  { id: 25, name: "감성적인", type: 1, cate: 0, subcate: 2 },
  { id: 26, name: "심플한", type: 1, cate: 0, subcate: 2 },
  { id: 27, name: "빈티지", type: 1, cate: 0, subcate: 2 },
  { id: 28, name: "특별한", type: 1, cate: 0, subcate: 2 },
  { id: 29, name: "일반", type: 0, cate: 0, subcate: 2 },
  { id: 30, name: "자연", type: 0, cate: 0, subcate: 2 },
  { id: 31, name: "레터링", type: 0, cate: 0, subcate: 2 },

  { id: 32, name: "도형", type: 0, cate: 0, subcate: 2 },
  { id: 33, name: "라벨", type: 0, cate: 0, subcate: 2 },
  { id: 34, name: "컨페티", type: 0, cate: 0, subcate: 2 },
  { id: 35, name: "패키지", type: 0, cate: 0, subcate: 2 },
  { id: 36, name: "일반", type: 0, cate: 0, subcate: 3 },
  { id: 37, name: "캐릭터", type: 0, cate: 0, subcate: 3 },
  { id: 38, name: "자연", type: 0, cate: 0, subcate: 3 },
  { id: 39, name: "패턴", type: 0, cate: 0, subcate: 3 },
  { id: 40, name: "레이스", type: 0, cate: 0, subcate: 3 },
  { id: 41, name: "레터링", type: 0, cate: 0, subcate: 3 },
  { id: 42, name: "데코", type: 0, cate: 0, subcate: 3 },
  { id: 43, name: "도형", type: 0, cate: 0, subcate: 3 },
  { id: 44, name: "패키지", type: 0, cate: 0, subcate: 3 },
  { id: 45, name: "귀여운", type: 1, cate: 0, subcate: 3 },
  { id: 46, name: "감성적인", type: 1, cate: 0, subcate: 3 },
  { id: 47, name: "심플한", type: 1, cate: 0, subcate: 3 },
  { id: 48, name: "빈티지", type: 1, cate: 0, subcate: 3 },
  { id: 49, name: "특별한", type: 1, cate: 0, subcate: 3 },
  { id: 50, name: "2022년", type: 0, cate: 1, subcate: 10 },
  { id: 51, name: "만년형", type: 0, cate: 1, subcate: 10 },
  { id: 52, name: "먼슬리", type: 0, cate: 1, subcate: 10 },
  { id: 53, name: "위클리", type: 0, cate: 1, subcate: 10 },
  { id: 54, name: "데일리", type: 0, cate: 1, subcate: 10 },
  { id: 55, name: "일기", type: 0, cate: 1, subcate: 10 },
  { id: 56, name: "직장", type: 0, cate: 1, subcate: 10 },
  { id: 57, name: "스터디", type: 0, cate: 1, subcate: 10 },
  { id: 58, name: "취미", type: 0, cate: 1, subcate: 10 },
  { id: 59, name: "육아/반려동물", type: 0, cate: 1, subcate: 10 },
  { id: 60, name: "가계부", type: 0, cate: 1, subcate: 10 },
  { id: 61, name: "건강", type: 0, cate: 1, subcate: 10 },
  { id: 62, name: "여행", type: 0, cate: 1, subcate: 10 },
  { id: 63, name: "귀여운", type: 1, cate: 1, subcate: 10 },
  { id: 64, name: "감성적인", type: 1, cate: 1, subcate: 10 },
  { id: 65, name: "심플한", type: 1, cate: 1, subcate: 10 },
  { id: 66, name: "빈티지", type: 1, cate: 1, subcate: 10 },
  { id: 67, name: "특별한", type: 1, cate: 1, subcate: 10 },
  { id: 68, name: "귀여운", type: 1, cate: 1, subcate: 11 },
  { id: 69, name: "감성적인", type: 1, cate: 1, subcate: 11 },
  { id: 70, name: "심플한", type: 1, cate: 1, subcate: 11 },
  { id: 71, name: "빈티지", type: 1, cate: 1, subcate: 11 },
  { id: 72, name: "특별한", type: 1, cate: 1, subcate: 11 },
  { id: 73, name: "귀여운", type: 1, cate: 1, subcate: 12 },
  { id: 74, name: "감성적인", type: 1, cate: 1, subcate: 12 },
  { id: 75, name: "심플한", type: 1, cate: 1, subcate: 12 },
  { id: 76, name: "빈티지", type: 1, cate: 1, subcate: 12 },
  { id: 77, name: "특별한", type: 1, cate: 1, subcate: 12 },
  { id: 78, name: "무지", type: 0, cate: 1, subcate: 11 },
  { id: 79, name: "줄노트", type: 0, cate: 1, subcate: 11 },
  { id: 80, name: "격자노트", type: 0, cate: 1, subcate: 11 },
  { id: 81, name: "점노트", type: 0, cate: 1, subcate: 11 },
  { id: 82, name: "직장", type: 0, cate: 1, subcate: 11 },
  { id: 83, name: "스터디", type: 0, cate: 1, subcate: 11 },
  { id: 84, name: "취미", type: 0, cate: 1, subcate: 11 },
  { id: 85, name: "트래커/챌린지", type: 0, cate: 1, subcate: 11 },
  { id: 86, name: "체크리스트", type: 0, cate: 1, subcate: 11 },
  { id: 87, name: "커버", type: 0, cate: 1, subcate: 12 },
  { id: 88, name: "배경지", type: 0, cate: 1, subcate: 12 },
  { id: 89, name: "속지", type: 0, cate: 1, subcate: 12 },
  { id: 90, name: "흑백", type: 1, cate: 1, subcate: 10 },
  { id: 91, name: "2023년", type: 0, cate: 1, subcate: 10 },
];

export const bankList = [
  { id: 1, value: "국민은행" },
  { id: 2, value: "기업은행" },
  { id: 3, value: "농협은행" },
  { id: 4, value: "신한은행" },
  { id: 5, value: "하나은행" },
  { id: 6, value: "우리은행" },
  { id: 7, value: "산업은행" },
  { id: 8, value: "제일은행" },
  { id: 9, value: "카카오뱅크" },
  { id: 10, value: "토스뱅크" },
  { id: 11, value: "우체국" },
  { id: 12, value: "한국씨티은행" },
  { id: 13, value: "케이뱅크" },
  { id: 14, value: "지역농축협" },
  { id: 15, value: "새마을금고" },
  { id: 16, value: "수협은행" },
  { id: 17, value: "신협" },
  { id: 18, value: "저축은행" },
  { id: 19, value: "경남은행" },
  { id: 20, value: "광주은행" },
  { id: 21, value: "대구은행" },
  { id: 22, value: "부산은행" },
  { id: 23, value: "전북은행" },
  { id: 24, value: "제주은행" },
  { id: 25, value: "HSBC은행" },
  { id: 26, value: "도이치은행" },
  { id: 27, value: "JP모간체이스은행" },
  { id: 28, value: "BOA은행" },
  { id: 29, value: "BNP파리바은행" },
  { id: 30, value: "중국공상은행" },
  { id: 31, value: "산립조합" },
  { id: 32, value: "중국건설은행" },
];

export const priceList = [
  { id: "1", price: "0" },
  { id: "2", price: "1500" },
  { id: "3", price: "3000" },
  { id: "4", price: "4400" },
  { id: "5", price: "6000" },
  { id: "6", price: "7000" },
  { id: "7", price: "8800" },
  { id: "8", price: "9900" },
  { id: "9", price: "12000" },
  { id: "10", price: "14000" },
  { id: "11", price: "15000" },
  { id: "12", price: "17000" },
  { id: "13", price: "19000" },
  { id: "14", price: "20000" },
  { id: "15", price: "22000" },
  { id: "16", price: "23000" },
  { id: "17", price: "25000" },
];
