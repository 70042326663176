import React, { useContext } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import logo from "../../static/img/logo_primary.png";
import { Spinner } from "../../components";
import AuthContext from "../../contexts/AuthContext";

const Home = ({ type = "page" }) => {
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);

  useEffect(() => {
    if (type !== "page") return;
    if (authData.loading) return;
    setTimeout(() => {
      if (authData.token) navigate("/admin/dashboard");
      else navigate("/auth/login");
    }, 1500);
  }, [authData]);

  return (
    <div
      className="flex flex-col items-center justify-center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <img src={logo} alt="logo" width="200px" />
      <div className="flex items-center mt-8">
        <div className="p mr-5 text-lg font-bold">Loading...</div>
        <Spinner />
      </div>
    </div>
  );
};

export default Home;
