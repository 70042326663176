import classnames from 'classnames';

const Input = ({
  type = 'text',
  variant = 'default',
  color = 'primary',
  placeholder,
  name,
  value,
  required,
  onChange,
  className,
  ...props
}) => {
  const colorVariants = {
    default: {
      primary: `text-gray-900`,
      white: `text-white`,
      gray: `text-gray`,
    },
    outlined: {
      primary: `border border-primary text-primary`,
      white: `border border-white text-white`,
      gray: `border text-gray-400 border-gray-300`,
    },
  };

  if (!colorVariants[variant] === undefined)
    throw new Error(`No variant found for: [${variant}]`);
  if (!colorVariants[variant][color] === undefined)
    throw new Error(`No color found for variant: [${variant}, ${color}]`);

  return (
    <input
      type={type}
      name={name}
      className={classnames(
        `block w-full px-4 py-2 text-sm rounded-full focus:ring-0 focus:outline-0 ${colorVariants[variant][color]}`,
        className
      )}
      placeholder={placeholder}
      value={value}
      required={required}
      onChange={onChange}
      {...props}
    />
  );
};

export default Input;
