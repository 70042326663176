import { RxCross2 } from "react-icons/rx";

import { Button, RadioButton } from "../../../components";
import { useState } from "react";
import { retireUser } from "../../../apis";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { designerIdState, userIdState } from "../../../atoms";
import { useNavigate } from "react-router-dom";

const LeaveModal = ({ setOpenLeaveModal }) => {
  const userId = useRecoilValue(userIdState);
  const setDesignerId = useSetRecoilState(designerIdState);
  const setUserId = useSetRecoilState(userIdState);
  const navigate = useNavigate();

  const handleClickCancel = () => {
    setOpenLeaveModal(false);
  };

  const [checked, setChecked] = useState(false);
  const handleCheckToggle = () => {
    setChecked((prev) => !prev);
  };

  const retiredUser = () => {
    try {
      const data = retireUser({ userId });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      setDesignerId(null);
      setUserId(null);
      navigate("/auth/login");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="leave_modal relative max-w-sm w-full rounded-2xl bg-white p-6">
      <h4 className="text-lg font-bold text-center">아쉬워요 :(</h4>

      <p className="text-center text-base text-gray-500 mt-4">
        탈퇴 시 작가님의 판매되고 있는 <br />
        <span className="text-primary">상품 내역이 모두 삭제</span>됨과 동시에
        <br />
        정산금액이 있는 경우{" "}
        <span className="text-primary">정산신청이 불가능</span>합니다.
      </p>

      <div className="mt-4 flex justify-center">
        <RadioButton checked={checked} onChange={handleCheckToggle} />
        <p className="ml-2">내용을 인지하였습니다</p>
      </div>

      <div className="flex items-center justify-center gap-2 mt-4">
        <Button
          variant="outlined"
          color="gray"
          size="lgs"
          onClick={handleClickCancel}
        >
          취 소
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="lgs"
          className="disabled:bg-gray-400"
          disabled={!checked}
          onClick={retiredUser}
        >
          탈 퇴
        </Button>
      </div>

      <div
        className="absolute top-3 right-3 p-1 cursor-pointer"
        onClick={handleClickCancel}
      >
        <RxCross2 />
      </div>
    </div>
  );
};

export default LeaveModal;
