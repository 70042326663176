import { createContext, useEffect, useState } from "react";
import cookieHelper from "../utils/cookieHelper";

const AuthContext = createContext({ token: null });

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState({
    token: null,
    loading: true,
  });

  useEffect(() => {
    const token = cookieHelper.getCookie("seller_access_token");

    if (token) {
      setTimeout(() => {
        setAuthData({ token, loading: false });
      }, 2000);
    } else {
      setTimeout(() => {
        setAuthData({ token: null, loading: false });
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ authData, setAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};
