import formatDate from "./formatDate";

export const formatCate = (id) => {
  if (id === "0") return "스티커";
  if (id === "1") return "다이어리";
};

export const formatSubcate = (id) => {
  if (id === "0") return "캐릭터 스티커";
  if (id === "1") return "메모지";
  if (id === "2") return "데코 스티커";
  if (id === "3") return "마스킹 테이프";
  if (id === "10") return "다이어리/플래너";
  if (id === "11") return "노트";
  if (id === "12") return "속지";
};

export const formatBusinessType = (id) => {
  if (id === "0") return "개인";
  if (id === "1") return "사업자(개인)";
  if (id === "2") return "사업자(법인)";
};

export const formatUserState = (value) => {
  if (value === true) return "탈퇴";
  if (value === false) return "정상";
};

export const formatProductState = (id) => {
  if (id === "0") return "임시저장";
  if (id === "1") return "승인대기";
  if (id === "2") return "승인반려";
  if (id === "10") return "승인완료";
  if (id === "11") return "판매중지";
  if (id === "50") return "삭제";
};

export const formatPeriod = (period) => {
  const today = new Date();

  if (period === "today") {
    return {
      sdate: formatDate(today),
      edate: formatDate(today),
    };
  } else if (period === "week") {
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return {
      sdate: formatDate(oneWeekAgo),
      edate: formatDate(today),
    };
  } else if (period === "month") {
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    return {
      sdate: formatDate(oneMonthAgo),
      edate: formatDate(today),
    };
  } else if (period === "tmonth") {
    const threeMonthsAgo = new Date(today);
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    return {
      sdate: formatDate(threeMonthsAgo),
      edate: formatDate(today),
    };
  }
};

export function formatDiscount(originalPrice, discountedPrice) {
  if (originalPrice <= 0 || discountedPrice <= 0) {
    return 0;
  }

  const discountAmount = originalPrice - discountedPrice;
  const discountRate = (discountAmount / originalPrice) * 100;

  return discountRate;
}

export const formatPrice = (id) => {
  if (id === "1") return "0";
  if (id === "2") return "1,500";
  if (id === "3") return "3,000";
  if (id === "4") return "4,400";
  if (id === "5") return "6,000";
  if (id === "6") return "7,000";
  if (id === "7") return "8,800";
  if (id === "8") return "9,900";
  if (id === "9") return "12,000";
  if (id === "10") return "14,000";
  if (id === "11") return "15,000";
  if (id === "12") return "17,000";
  if (id === "13") return "19,000";
  if (id === "14") return "20,000";
  if (id === "15") return "22,000";
  if (id === "16") return "23,000";
  if (id === "17") return "25,000";
};

export function formatCompanyNum(companyNum) {
  if (companyNum.length !== 10) {
    return companyNum;
  }

  const firstPart = companyNum.slice(0, 2);
  const secondPart = companyNum.slice(2, 5);
  const thirdPart = companyNum.slice(5);

  return `${firstPart}-${secondPart}-${thirdPart}`;
}

export function formatPricewithComma(price) {
  if (!price) return;

  if (typeof price === "number") {
    price = price.toString();
  }

  return price.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function calculateWithholdingTax(designerEarn, vat) {
  if (!designerEarn || !vat) return null;

  const result = (designerEarn - vat) * 0.033;
  return Math.floor(result / 10) * 10; // Rounds down to the nearest 10
}