import { useEffect, useRef } from "react";

const ModalBase = ({ children, className = "", onClose }) => {
  const modalRef = useRef(null);

  const handleClickOutside = (e) => {
    if (onClose && modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 right-0 bottom-0 backdrop-blur-sm bg-[#00000045] p-3 f-center z-10 ${className}`}
    >
      <div ref={modalRef}> {children}</div>
    </div>
  );
};

export default ModalBase;
