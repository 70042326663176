import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Sidebar } from "../../components";
import AuthContext from "../../contexts/AuthContext";
import Home from "../Home/Home";

const AdminOutlet = () => {
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);

  useEffect(() => {
    if (authData.loading) return;
    if (!authData.token) navigate("/auth/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData]);

  return (
    <>
      {authData.loading ? (
        <Home type="component" />
      ) : (
        <>
          <Toaster />
          <div className="admin_panel flex bg-primary font-medium">
            <div className="sidebar hidden w-2/6 max-w-xs md:min-w-max md:block lg:w-1/5">
              <Sidebar />
            </div>
            <div className="main min-h-screen w-full bg-white overflow-hidden md:rounded-tl-[50px] md:rounded-bl-[50px]">
              <div className="p-2 mx-auto mb-10 w-full max-w-screen-2xl md:p-5 xl:p-10">
                <Outlet />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdminOutlet;
