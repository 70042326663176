import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { PageLoading } from "./components";
import { AuthProvider } from "./contexts/AuthContext";
import {
  AddProduct,
  AdminOutlet,
  Announcement,
  Auth,
  DashboardSeller,
  Home,
  Login,
  Products,
  Register,
  SalesRecords,
  SettlementRecords,
} from "./pages";
import ProfileArtist from "./pages/Profile/ProfileArtist";
import EditArtist from "./pages/Profile/EditArtist";
import UpdateProduct from "./pages/Products/UpdateProduct";

const Page404 = React.lazy(() => import("./pages/Page404/Page404"));

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/admin",
      element: <AdminOutlet />,
      children: [
        {
          path: "",
          element: <DashboardSeller />,
          index: true,
        },
        {
          path: "dashboard",
          element: <DashboardSeller />,
        },
        {
          path: "products",
          element: <Products />,
        },
        {
          path: "products/list",
          element: <Products />,
        },
        {
          path: "products/register",
          element: <AddProduct />,
        },
        {
          path: "products/edit/:id",
          element: <UpdateProduct />,
        },
        {
          path: "sales",
          element: <SalesRecords />,
        },
        {
          path: "sales/records",
          element: <SalesRecords />,
        },
        {
          path: "settlement",
          element: <SettlementRecords />,
        },
        {
          path: "settlement/records",
          element: <SettlementRecords />,
        },
        {
          path: "announcement",
          element: <Announcement />,
        },
        {
          path: "profile",
          element: (
            <React.Suspense fallback={<PageLoading />}>
              <ProfileArtist />
            </React.Suspense>
          ),
        },
        {
          path: "profile-edit",
          element: (
            <React.Suspense fallback={<PageLoading />}>
              <EditArtist />
            </React.Suspense>
          ),
        },
      ],
    },
    {
      path: "/auth",
      element: <Auth />,
      children: [
        {
          path: "",
          element: <Login />,
          index: true,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "register",
          element: <Register />,
        },
      ],
    },
    {
      path: "*",
      element: (
        <React.Suspense fallback={<PageLoading />}>
          <Page404 />
        </React.Suspense>
      ),
    },
  ]);

  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
