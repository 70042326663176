import { RxCross2 } from "react-icons/rx";

import { Button } from "../../../components";

const LogoutModel = ({ onLogout }) => {
    const handleClickLogout = (e) => {
        if (!onLogout) return;
        onLogout(e, true);
    };

    const handleClickCancel = (e) => {
        if (!onLogout) return;
        onLogout(e, false);
    };

    return (
        <div className="logout_model relative max-w-sm w-full rounded-2xl bg-white p-6">
            <h4 className="text-lg font-bold text-center">로그아웃</h4>

            <p className="text-center text-base text-gray-500 mt-0.5">
                로그아웃 할까요?
            </p>

            <div className="flex items-center justify-center gap-2 mt-4">
                <Button
                    variant="outlined"
                    color="gray"
                    size="lgs"
                    onClick={handleClickCancel}
                >
                    취 소
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="lgs"
                    onClick={handleClickLogout}
                >
                    확 인
                </Button>
            </div>

            <div
                className="absolute top-3 right-3 p-1 cursor-pointer"
                onClick={handleClickCancel}
            >
                <RxCross2 />
            </div>
        </div>
    );
};

export default LogoutModel;
