import { useState } from "react";
import { MdClose } from "react-icons/md";

import { Button, FlagPicker, Input } from "../../../components";
import { changePwd, checkEamilsms, sendFindEmailsms } from "../../../apis";

const activeCompType = {
  email_fogrot: "email_fogrot",
  email_result: "email_result",
  password_fogrot: "password_fogrot",
  password_result: "password_result",
};

const ForgotPassword = ({ onClose, lang }) => {
  const [activeComp, setActiveComp] = useState(activeCompType.email_fogrot);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const findEmail = async (phone, acode) => {
    try {
      const { data } = await checkEamilsms({
        data: { phone, acode },
      });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      setEmail(data.email);
      setMessage("");
      setActiveComp(activeCompType.email_result);
    } catch (err) {
      setMessage("인증번호를 확인해주세요");
      console.log(err);
    }
  };

  const resetPassword = async (pwd, chkpwd) => {
    try {
      const data = await changePwd({
        data: {
          email: email,
          password: pwd,
          rePassword: chkpwd,
        },
      });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      setActiveComp(activeCompType.password_result);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickForgotPassword = (e) => {
    setActiveComp(activeCompType.password_fogrot);
  };

  const handleClickClose = (e) => {
    if (!onClose) return;
    onClose(e);
  };
  return (
    <div className="relative w-full max-w-sm p-8 pt-5 bg-white forgot_password_model slide-top rounded-xl">
      <div className="absolute cursor-pointer top-2 right-2">
        <MdClose className="text-xl" onClick={handleClickClose} />
      </div>

      {activeComp !== activeCompType.password_result && (
        <div className="mb-4 heading">
          <h2 className="text-lg font-semibold text-center">
            {lang === "korean"
              ? "이메일/비밀번호 찾기"
              : "Fogot Email/Password"}
          </h2>
        </div>
      )}

      {activeComp === activeCompType.email_fogrot && (
        <EmailComponent lang={lang} findEmail={findEmail} message={message} />
      )}
      {activeComp === activeCompType.email_result && (
        <EmailResultComponent email={email} lang={lang} />
      )}
      {activeComp === activeCompType.email_result && (
        <Button
          className="w-full mt-8"
          variant="contained"
          color="primary"
          size="lg"
          onClick={handleClickForgotPassword}
        >
          {lang === "korean" ? "비밀번호 재설정하기" : "Reset your password"}
        </Button>
      )}

      {activeComp === activeCompType.password_fogrot && (
        <PasswordComponent
          lang={lang}
          email={email}
          message={message}
          setMessage={setMessage}
          resetPassword={resetPassword}
        />
      )}
      {activeComp === activeCompType.password_result && (
        <>
          <div className="mb-8 heading">
            <h4 className="text-lg font-semibold text-center">
              {lang === "korean"
                ? "비밀번호 변경 완료!"
                : "Password change complete!"}
            </h4>
          </div>
          <Button
            className="w-full"
            variant="contained"
            color="primary"
            size="lg"
            onClick={handleClickClose}
          >
            {lang === "korean" ? "로그인 하기" : "LOG IN"}
          </Button>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;

// 휴대폰 번호 입력후 인증
const EmailComponent = ({ lang, findEmail, message }) => {
  const [activePart, setActivePart] = useState("mobile");
  const [phone, setPhone] = useState("");
  const [acode, setAcode] = useState("");

  const sendIdsms = async () => {
    try {
      const data = await sendFindEmailsms({ data: { phone } });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
      setActivePart("otp");
    } catch (err) {
      alert("일치하는 회원이 없습니다");
      console.log(err);
    }
  };

  const handleClickSetPart = async (e) => {
    if (activePart === "mobile") {
      sendIdsms(phone);
    }
    if (activePart === "otp" && findEmail) findEmail(phone, acode);
  };

  const handleKeyPressSendSms = (e) => {
    if (e.key === "Enter") {
      handleClickSetPart();
    }
  };

  return (
    <>
      <div className="inputs">
        <div className="flex gap-3 mb-3">
          <FlagPicker />
          <Input
            variant="outlined"
            color="gray"
            className="!text-font-input"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value.trim())}
            onKeyPress={handleKeyPressSendSms}
            placeholder={
              lang === "korean" ? "숫자만 입력해주세요" : "Enter numbers only"
            }
          />
        </div>
        {activePart === "otp" && (
          <Input
            variant="outlined"
            color="gray"
            name="acode"
            value={acode}
            onChange={(e) => setAcode(e.target.value.trim())}
            placeholder={
              lang === "korean"
                ? "인증번호를 입력해주세요"
                : "Please enter your verification code"
            }
          />
        )}
      </div>
      <p className="mt-2 text-xs text-center text-primary">{message}</p>

      <div className="w-full mt-8 action_buttons">
        <Button
          variant="contained"
          color="primary"
          className="w-full"
          size="lg"
          onClick={handleClickSetPart}
        >
          {activePart === "mobile" &&
            (lang === "korean" ? "휴대폰 인증하기" : "Verify")}
          {activePart === "otp" &&
            (lang === "korean" ? "이메일 찾기" : "FIND EMAIL")}
        </Button>
      </div>
    </>
  );
};

// 이메일 결과
const EmailResultComponent = ({ lang, email }) => {
  return (
    <div className="flex flex-col gap-1 text-center">
      <p>
        {lang === "korean"
          ? "작가님이 등록하신 이메일은"
          : "The email registered by the author is"}
      </p>
      <p className="font-semibold text-primary">{email}</p>
      <p>{lang === "korean" ? "입니다 :)" : "no see :)"}</p>
    </div>
  );
};

// 비밀번호 재설정
const PasswordComponent = ({
  lang,
  email,
  resetPassword,
  message,
  setMessage,
}) => {
  const [pwd, setPwd] = useState("");
  const [chkpwd, setChkPwd] = useState("");
  const handleClickResetPass = () => {
    const comfirmPwd = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,20}$/;
    if (pwd === "" || chkpwd === "") {
      setMessage("비밀번호를 입력해주세요");
      return;
    }

    if (pwd !== chkpwd) {
      setMessage("비밀번호가 일치하지 않습니다");
      return;
    }

    if (!comfirmPwd.test(pwd)) {
      setMessage("영문,숫자,특수기호 혼합 8~20자");
      return;
    }
    resetPassword(pwd, chkpwd);
    setMessage("");
  };
  return (
    <div>
      <p className="mb-5 font-semibold text-center text-primary">{email}</p>

      <Input
        className="mb-3"
        variant="outlined"
        color="gray"
        type="password"
        name="pwd"
        value={pwd}
        onChange={(e) => setPwd(e.target.value.trim())}
        placeholder={
          lang === "korean"
            ? "재설정 할 비밀번호를 입력해주세요"
            : "Please enter the password to reset"
        }
      />
      <Input
        variant="outlined"
        color="gray"
        type="password"
        name="chkpwd"
        value={chkpwd}
        onChange={(e) => setChkPwd(e.target.value.trim())}
        placeholder={
          lang === "korean"
            ? "비밀번호를 다시 입력해주세요"
            : "Please re-enter your password"
        }
      />
      <p className="mt-2 text-xs text-center text-primary">{message}</p>

      <Button
        className="w-full mt-8"
        variant="contained"
        color="primary"
        onClick={handleClickResetPass}
      >
        {lang === "korean" ? "비밀번호 재설정하기" : "RESET PASSWORD"}
      </Button>
    </div>
  );
};
