import { useState } from "react";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { Button, FlagPicker, Input } from "../../../components";
import { checkAuthsms, sendAuthsms, sendAuthsmsTwo } from "../../../apis";
import { toast } from "react-hot-toast";

const steps = {
  mobile: "mobile",
  otp: "otp",
  completed: "completed",
};

const MobileVerificationModal = ({
  onClose,
  createdNewDesigner,
  phone,
  setPhone,
}) => {
  const [step, setStep] = useState(steps.mobile);
  const [acode, setAcode] = useState("");

  const sendAuthSms = async () => {
    try {
      const data = await sendAuthsmsTwo({ data: { phone } });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      setStep(steps.otp);
    } catch (err) {
      alert(err.response.data.error_string);
      console.log(err);
    }
  };

  const handleKeyPressSendSms = (e) => {
    if (e.key === "Enter") {
      sendAuthSms();
    }
  };

  const checkAuthCode = async () => {
    try {
      const data = await checkAuthsms({
        data: { phone, acode },
      });

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      await createdNewDesigner();

      setStep(steps.completed);
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyPressCheckAuth = (e) => {
    if (e.key === "Enter") {
      checkAuthCode();
    }
  };

  const handleClickClose = (e) => {
    if (onClose) onClose(e);
  };

  return (
    <div className="relative w-full max-w-sm p-8 pt-5 bg-white mobile_number_verification_modal slide-top rounded-xl">
      {step !== steps.completed && (
        <div className="absolute cursor-pointer top-2 right-2">
          <MdClose className="text-xl" onClick={handleClickClose} />
        </div>
      )}

      {step !== steps.completed && (
        <div className="mb-4 heading">
          <h2 className="text-lg font-semibold text-center">휴대폰 인증하기</h2>
        </div>
      )}

      {(step === steps.mobile || step === steps.otp) && (
        <>
          <div className="inputs">
            <div className="flex gap-3 mb-3">
              <FlagPicker />
              <Input
                variant="outlined"
                color="gray"
                className="!text-font-input"
                placeholder="숫자만 입력해주세요"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value.trim())}
                onKeyPress={handleKeyPressSendSms}
              />
            </div>

            {step === steps.otp && (
              <Input
                variant="outlined"
                color="gray"
                className="!text-font-input"
                placeholder="인증번호를 입력해주세요"
                name="acode"
                value={acode}
                onChange={(e) => setAcode(e.target.value.trim())}
                onKeyPress={handleKeyPressCheckAuth}
              />
            )}
          </div>

          <div className="w-full mt-8 action_button">
            {step === steps.mobile && (
              <Button
                variant="contained"
                color="primary"
                className="w-full"
                size="lg"
                onClick={sendAuthSms}
              >
                인증번호 보내기
              </Button>
            )}

            {step === steps.otp && (
              <Button
                variant="contained"
                color="primary"
                className="w-full"
                size="lg"
                onClick={checkAuthCode}
              >
                등록완료!
              </Button>
            )}
          </div>
        </>
      )}

      {step === steps.completed && (
        <>
          <div className="mb-6 text-center">
            <p className="mb-1">자꾸다꾸 작가로</p>
            <p className="text-2xl font-bold">등록되었어요🎉</p>
          </div>
          <Link to="/auth/login">
            <Button
              variant="contained"
              color="primary"
              className="w-full"
              size="lg"
            >
              작가 로그인
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default MobileVerificationModal;
