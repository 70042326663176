import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { toast } from "react-hot-toast";

import {
  downloadExcel,
  getProfile,
  getUserReqs,
  requestSettlement,
  settlementRecords,
} from "../../apis";
import { designerIdState } from "../../atoms";
import formatDate from "../../utils/formatDate";
import { Button, Input, Pagination, Spinner } from "../../components";
import { formatPeriod, formatPricewithComma } from "../../utils/formatData";

import excelIcon from "../../static/img/excel.png";

const SettlementRecords = () => {
  const today = new Date();
  const [data, setData] = useState({});
  const [period, setPeriod] = useState("");
  const designerId = useRecoilValue(designerIdState);
  const [user, setUser] = useState({});
  const [reqs, setReqs] = useState([]);
  const [type, setType] = useState("none");
  const [page, setPage] = useState({
    currentPage: 1,
    totalCount: 0,
    pageSize: 15,
    loading: false,
  });
  const [selectedDate, setSelectedDate] = useState({
    sdate: "",
    edate: ""
  });

  const [excelDownload, setExcelDownload] = useState(false);

  const fetchWithdraw = async (params, headers) => {
    try {
      const { data } = await settlementRecords(params, headers);

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      setData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserInfo = async () => {
    try {
      const { data } = await getProfile({
        id: designerId,
      });

      setUser(data.profile);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchReqs = async (params, headers) => {
    setPage({ ...page, loading: true });
    try {
      const { data } = await getUserReqs(params, headers);

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      setReqs(data.items);

      const { page, per_page, totalCount } = data;
      setPage({
        ...page,
        currentPage: page,
        pageSize: per_page,
        totalCount: totalCount,
        loading: false,
      });
    } catch (err) {
      setPage({ ...page, loading: false });

      console.log(err);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchWithdraw();
      await fetchUserInfo();
  
      // Fetch requests with start date as 2023-01-01 and end date as today.
      fetchReqs({
        dpid: designerId,
        start: "2023-01-01",
        end: formatDate(new Date()),
        page: page.currentPage,
        pageSize: page.pageSize,
      });
    };
  
    fetchInitialData();
  }, []);
  

  useEffect(() => {
    const periodValue = formatPeriod(period);
    if (type === "period" && period && period !== "none") {
      fetchReqs({
        dpid: designerId,
        start: periodValue.sdate,
        end: periodValue.edate,
        page: page.currentPage,
      });
    } else if (type === "selectedDate" && selectedDate.sdate && selectedDate.edate) {
      fetchReqs({
        dpid: designerId,
        start: selectedDate.sdate,
        end: selectedDate.edate,
        page: page.currentPage,
      });
    }
  }, [page.currentPage, period, selectedDate]);

  // 날짜 직접 선택
  const handleChangeDate = (e) => {
    setType("selectedDate");
    const { name, value } = e.target;
    setSelectedDate({ ...selectedDate, [name]: value });
    setPage({ ...page, currentPage: 1 });
  };

  // const handleClickDownloadExcelFile = (e) => {
  //   setExcelDownload(true);
  //   try {
  //     window.open(
  //       `${config.apiUrl}/sales/withdraw/excel?page=${page.currentPage}&perpage=${page.pageSize}&sdate=${page.startDate}&edate=${page.endDate}`,
  //       "_blank"
  //     );

  //     setExcelDownload(false);
  //   } catch (err) {
  //     setExcelDownload(false);
  //   }
  // };

  const handleClickDownloadExcelFile = async () => {
    setExcelDownload(true);
    try {
      await downloadExcel(
        {
          page: page.currentPage,
          perpage: page.pageSize,
          sdate: selectedDate.sdate, // 혹은 page.startDate, 구현에 따라 다름
          edate: selectedDate.edate, // 혹은 page.endDate, 구현에 따라 다름
        },
        {
          /* 필요한 헤더 정보 */
        }
      );
    } catch (err) {
      console.error("Error downloading excel:", err);
    } finally {
      setExcelDownload(false);
    }
  };

  const requestSettlements = async (availableBalance) => {
    try {
      console.log(availableBalance);
      const formData = new FormData();
      const data = await requestSettlement(formData);
      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }
      if (availableBalance === 0) {
        toast.error("정산 신청 가능금액이 없습니다.");
        return;
      }
      if (availableBalance < 10000) {
        toast.error("정산 신청 가능금액은 10,000원 이상입니다.");
        return;
      };
      if (data.data.error_string) {
        toast.error(data.data.error_string);
        return;
      }
      toast.success("정산 신청이 완료되었습니다.");
      fetchWithdraw();
      fetchUserInfo();
    } catch (err) {
      console.log(err);
      toast.error("잠시 후 다시 시도해주세요.");
    }
  };

  const handlePageChange = (value) => {
    if (value === page.currentPage) return;
    setPage({ ...page, currentPage: value });
  };

  const handleChangePeriod = (e) => {
    setType("period");
    const newPeriod = e.target.value;
    if (newPeriod === "today") {
      handleTodayClick();
    } else if (newPeriod === "week") {
      handleWeekClick();
    } else if (newPeriod === "month") {
      handleMonthClick();
    } else if (newPeriod === "tmonth") {
      handleThreeMonthsClick();
    }
    if (newPeriod !== period) {
      setPeriod(newPeriod);
      setPage({ ...page, currentPage: 1 });
    }
  };

  const calculateDate = (days) => {
    const result = new Date();
    result.setDate(result.getDate() - days);
    return formatDate(result);
  };

  const handleTodayClick = () => {
    setSelectedDate({ ...selectedDate, sdate: formatDate(new Date()) });
  };

  const handleWeekClick = () => {
    setSelectedDate({ ...selectedDate, sdate: calculateDate(7) });
  };

  const handleMonthClick = () => {
    setSelectedDate({ ...selectedDate, sdate: calculateDate(30) });
  };

  const handleThreeMonthsClick = () => {
    setSelectedDate({ ...selectedDate, sdate: calculateDate(90) });
  };

  return (
    <div className="sales_record_page">
      <section className="flex items-center justify-between px-4 page_header">
        <h2 className="text-base">정산기록</h2>
        <p className="text-base text-primary">정산안내</p>
      </section>

      <hr className="my-6 border-gray-200" />

      <section className="px-4 sales_info">
      <h5 className="text-3xl font-medium leading-normal">
        {user?.brand_name} 작가님의 <br /> 정산 가능금액은{" "}
        <span className="font-semibold text-primary">
          {
            // Check if all values are "0" and handle this case explicitly
            data?.withval?.paid_price === "0" && data?.withval?.comm === "0" && data?.withval?.vat === "0"
            ? "0원"
            : formatPricewithComma(
                parseFloat(data?.withval?.paid_price || 0) -
                parseFloat(data?.withval?.comm || 0) -
                parseFloat(data?.withval?.vat || 0) -
                (user?.business_type === "0" 
                  ? Math.floor(
                      ((parseFloat(data?.withval?.paid_price || 0) -
                        parseFloat(data?.withval?.comm || 0) -
                        parseFloat(data?.withval?.vat || 0)) * 0.033) / 10
                    ) * 10
                  : 0)
              ) + "원"
          }
        </span>
        입니다 :)
      </h5>

        {Object.keys(data).length === 0 ? (
          <p className="mt-4 text-base text-primary">
            마이페이지에서 정산정보를 입력해주세요.
          </p>
        ) : (
          <p className="mt-4 text-base text-primary">
            판매합계금액 - 수수료 - 원천징수세액 = 정산금액
          </p>
        )}

        <ul className="mt-2 text-sm text-gray-700">
          <li> · 수수료 : 애플 인앱결제 수수료 15% + 자꾸다꾸 수수료 10%</li>
          {user?.business_type === "0" && (
          <li>
            · 원천징수세액 : 판매합계금액에서 수수료를 공제한 금액 x 3.3%
          </li>
          )}
          <li>
            {" "}
            · 정산금액 : 판매합계금액에서 수수료와 원천징수세액을 공제한 금액
          </li>
        </ul>

        <div className="relative mt-8 overflow-x-auto">
          <table className="w-full text-sm text-center text-black border border-gray-300">
            <thead className="text-base bg-gray-100 border-b border-b-gray-300">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                >
                  판매합계금액
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                >
                  수수료
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                >
                  부가세
                </th>
                {user?.business_type === "0" && (
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                >
                  원천세
                </th>
                )}
                <th scope="col" className="px-6 py-4 font-medium border-r">
                  정산금액
                </th>
              </tr>
            </thead>
            <tbody className="font-medium">
              <tr className="bg-white border-b border-gray-300">
                <td className="px-6 py-3 text-center border-r border-r-gray-300 whitespace-nowrap">
                  {formatPricewithComma(data?.withval?.paid_price) || "-"}원
                </td>
                <td className="px-6 py-3 text-center border-r text-primary border-r-gray-300">
                  -{formatPricewithComma(data?.withval?.comm) || "-"}원
                </td>
                <td className="px-6 py-3 text-center border-r text-primary border-r-gray-300">
                  -{formatPricewithComma(data?.withval?.vat) || "-"}원
                </td>
                  {
                    user?.business_type === "0" && (
                      <td className="px-6 py-3 text-center border-r text-primary border-r-gray-300">
                        -{formatPricewithComma(
                          Math.floor(
                            ((parseFloat(data?.withval?.paid_price || 0) - parseFloat(data?.withval?.comm || 0) - parseFloat(data?.withval?.vat || 0)) * 0.033) / 10
                          ) * 10
                        ) || "0"}원
                      </td>
                    )
                  }
                <td className="px-6 py-3 text-center border-r border-r-gray-300">
                  {user?.business_type === "0" ? 
                    formatPricewithComma(
                      parseFloat(data?.withval?.paid_price || 0) - parseFloat(data?.withval?.comm || 0) - parseFloat(data?.withval?.vat || 0) - 
                      Math.floor(((parseFloat(data?.withval?.paid_price || 0) - parseFloat(data?.withval?.comm || 0) - parseFloat(data?.withval?.vat || 0)) * 0.033) / 10) * 10
                    ) || "0"
                    : 
                    formatPricewithComma(
                      parseFloat(data?.withval?.paid_price || 0) - parseFloat(data?.withval?.comm || 0)
                    ) || "0"
                  }원
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className="mt-3 text-sm font-normal text-gray">
          정산신청이 가능한 최소금액은 10,000원이며, 최근 정산일로부터 한
          달(30일) 이후로 신청이 가능합니다. <br />
          정산 신청 후 영업일 기준 3일 소요 됩니다.
        </p>

        <div className="my-8 f-center">
          <Button
            variant="contained"
            color="primary"
            size="lgs"
            onClick={() => requestSettlements(parseFloat(data?.withval?.paid_price || 0))}
          >
            정산 신청하기
          </Button>
        </div>
      </section>

      <hr className="my-6 border-gray-200" />

      <section className="relative px-4 sales_records">
        <div className="flex items-center sales_filtering">
          <Button
            variant={period === "today" ? "contained" : "default"}
            color={period === "today" ? "primary" : "gray"}
            size="sm"
            className="!rounded-full"
            onClick={handleChangePeriod}
            value="today"
          >
            오늘
          </Button>
          <Button
            variant={period === "week" ? "contained" : "default"}
            color={period === "week" ? "primary" : "gray"}
            size="sm"
            className="!rounded-full ml-2 text-gray-400"
            onClick={handleChangePeriod}
            value="week"
          >
            1주일
          </Button>
          <Button
            variant={period === "month" ? "contained" : "default"}
            color={period === "month" ? "primary" : "gray"}
            size="sm"
            className="!rounded-full ml-2 text-gray-400"
            onClick={handleChangePeriod}
            value="month"
          >
            1개월
          </Button>
          <Button
            variant={period === "tmonth" ? "contained" : "default"}
            color={period === "tmonth" ? "primary" : "gray"}
            size="sm"
            className="!rounded-full ml-2 text-gray-400"
            onClick={handleChangePeriod}
            value="tmonth"
          >
            3개월
          </Button>
          <div className="max-w-xs ml-4">
            <Input
              type="date"
              variant="outlined"
              color="gray"
              name="sdate"
              className="!py-1"
              value={selectedDate.sdate}
              onChange={handleChangeDate}
            />
          </div>
          <span className="block ml-2">~</span>
          <div className="max-w-xs ml-2">
            <Input
              type="date"
              variant="outlined"
              color="gray"
              name="edate"
              className="!py-1"
              value={selectedDate.edate}
              onChange={handleChangeDate}
            />
          </div>
          <div className="ml-auto">
            <Button
              variant="outlined"
              color="green"
              size="sm"
              className="!rounded-full f-center"
              disabled={excelDownload}
              onClick={handleClickDownloadExcelFile}
            >
              <img
                src={excelIcon}
                alt="excel"
                height="19px"
                width="19px"
                className="object-contain mr-2"
              />
              엑셀로 내려받기
            </Button>
          </div>
        </div>

        <hr className="my-5 border-gray-300" />

        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-black border border-gray-300 whitespace-nowrap">
            <thead className="text-base bg-gray-100 border-b border-b-gray-300">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                >
                  정산완료일
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                >
                  정산신청일
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                >
                  판매합계금액
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                >
                  수수료
                </th>
                {user?.business_type === "0" && (
                <th scope="col" className="px-6 py-4 font-medium text-center border-r border-r-gray-300">부가세</th>
                )}
                {user?.business_type === "0" && (
                <th scope="col" className="px-6 py-4 font-medium text-center border-r border-r-gray-300">원천세</th>
                )}
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                >
                  정산금액
                </th>
                <th
                  scope="col"
                  className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                >
                  정산방식
                </th>
                <th scope="col" className="px-6 py-4 font-medium text-center">
                  정산계좌
                </th>
              </tr>
            </thead>
            <tbody className="font-normal">
              {reqs?.map((settlement, i) => (
                <tr
                  key={i}
                  className="bg-white border-b border-gray-300 last:border-b-0"
                >
                  <td className="px-6 py-3 text-center border-r border-r-gray-300 whitespace-nowrap">
                    {formatDate(settlement?.sent_at) || "-"}
                  </td>
                  <td className="px-6 py-3 text-center border-r border-r-gray-300">
                    {formatDate(settlement?.created_at) || "-"}
                  </td>
                  <td className="px-6 py-3 text-center border-r border-r-gray-300">
                    {formatPricewithComma(settlement?.paid_price) || "-"}원
                  </td>
                  <td className="text-primary px-6 py-3 text-center border-r border-r-gray-300">
                    {formatPricewithComma(settlement?.comm) || "-"}원
                  </td>
                  {user?.business_type === "0" && (
                    <td className="text-primary px-6 py-3 text-center border-r border-r-gray-300">
                      {formatPricewithComma(settlement?.vat) || "-"}원
                    </td>
                  )}
                  {user?.business_type === "0" && (
                    <td className="text-primary px-6 py-3 text-center border-r border-r-gray-300">
                      {settlement?.designer_earn && settlement?.vat ? 
                        `${Math.floor(((parseFloat(settlement.designer_earn) - parseFloat(settlement.vat)) * 0.033) / 10) * 10}` : 
                        "-"}원
                    </td>
                  )}
                  <td className="px-6 py-3 text-center border-r border-r-gray-300">
                    {user?.business_type === "0" && settlement?.designer_earn && settlement?.vat ? 
                      `${formatPricewithComma(parseFloat(settlement.designer_earn) - parseFloat(settlement.vat) - Math.floor(((parseFloat(settlement.designer_earn) - parseFloat(settlement.vat)) * 0.033) / 10) * 10)}` : 
                      formatPricewithComma(settlement?.mvat) || "-"}원
                  </td>
                  <td className="px-6 py-3 text-center border-r border-r-gray-300">
                    {settlement?.memo || "-"}
                  </td>
                  <td className="px-6 py-3 text-center">
                    {data.bank_name || "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-10 pagination">
          <Pagination
            pageSize={page.pageSize}
            onPageChange={handlePageChange}
            totalCount={page.totalCount}
            currentPage={page.currentPage}
          />
        </div>

        {page.loading && (
          <div className="w-full min-h-[300px] f-center absolute top-0 left-0 right-0 bottom-0 z-10 bg-model">
            <Spinner />
          </div>
        )}
      </section>
    </div>
  );
};

export default SettlementRecords;
