import { useContext, useEffect, useState } from "react";
import { MdClose, MdKeyboardArrowRight } from "react-icons/md";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import {
  getProdudctsState,
  getSellChart,
  getSellState,
  settlementRecords,
} from "../../apis";

import { Button } from "../../components";
import formatDate from "../../utils/formatDate";
import { formatPricewithComma } from "../../utils/formatData";
import { Link } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";

const Dashboard = () => {
  const dateNow = new Date();
  const [chartData, setChartData] = useState([]);
  const [products, setProducts] = useState({});
  const [sells, setSells] = useState({});
  const [reqPrice, setReqPrice] = useState();
  const [chartFiltered, setChartFiltered] = useState({
    type: "COUNT",
    date: "DAY",
  });

  // const { authData } = useContext(AuthContext);
  // useEffect(() => {
  //   console.log(authData, "setAuthData?!?!?!?!?!?!?!?!?!?!?!?!?");
  // }, []);

  const fetchChartData = async (params, headers) => {
    try {
      const { data } = await getSellChart(params, headers);

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      setChartData(
        data?.salesStatistics?.map((item) => ({
          value: parseInt(item.value),
          date: item.date,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchWithdraw = async () => {
    try {
      const { data } = await settlementRecords();

      setReqPrice(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProductState = async () => {
    const { data } = await getProdudctsState();

    if (data.status > 299 || data.status < 200) {
      const message =
        data && data.message ? data.message : "Something went wrong";
      throw new Error(message);
    }

    setProducts(data);
  };

  const fetchSellState = async () => {
    const { data } = await getSellState();

    if (data.status > 299 || data.status < 200) {
      const message =
        data && data.message ? data.message : "Something went wrong";
      throw new Error(message);
    }

    setSells(data);
  };

  const handleChangeChartFilter = (type, value) => {
    if (type === "date") {
      setChartFiltered({ ...chartFiltered, date: value });
    } else if (type === "value") {
      setChartFiltered({ ...chartFiltered, type: value });
    }
  };

  useEffect(() => {
    fetchChartData({
      type: chartFiltered.type,
      dateType: chartFiltered.date,
    });
    fetchProductState();
    fetchSellState();
    fetchWithdraw();
  }, [chartFiltered.date, chartFiltered.type]);

  return (
    <div className="dashboard_page">
      <section className="info_section grid grid-cols-12 gap-8 mt-8">
        <div className="w-full col-span-full lg:col-span-3">
          <Link to="/admin/products/list">
            <h5 className="flex items-center pl-3 mb-3 font-medium">
              상품현황
              <MdKeyboardArrowRight className="ml-2 text-xl" />
            </h5>
          </Link>

          <div className="w-full rounded-3xl shadow-app1 p-8 h-full max-h-[244px]">
            <div className="flex items-center justify-between">
              <p className="text-2xl font-medium">상품 수</p>
              <p className="text-4xl text-primary font-fullbold f-center">
                {products?.totalProductCount
                  ? formatPricewithComma(products.totalProductCount)
                  : "0"}
                <span className="text-sm text-gray font-normal ml-1">건</span>
              </p>
            </div>

            <hr className="border-gray-200 my-5" />

            <div className="flex items-center justify-between mb-2">
              <p className="text-base text-gray font-medium">승인완료</p>
              <p className="text-lg text-primary font-bold f-center">
                {products?.approvedProductCount || "0"}
                <span className="text-sm text-gray font-normal ml-1">건</span>
              </p>
            </div>

            <div className="flex items-center justify-between mb-2">
              <p className="text-base text-gray font-medium">승인대기</p>
              <p className="text-lg text-primary font-bold f-center">
                {products?.waitingProductCount || "0"}
                <span className="text-sm text-gray font-normal ml-1">건</span>
              </p>
            </div>

            <div className="flex items-center justify-between">
              <p className="text-base text-gray font-medium">승인반려</p>
              <p className="text-lg text-primary font-bold f-center">
                {products?.rejectedProductCount || "0"}
                <span className="text-sm text-gray font-normal ml-1">건</span>
              </p>
            </div>
          </div>
        </div>

        <div className="w-full col-span-full lg:col-span-6">
          <Link to="/admin/sales/records">
            <h5 className="flex items-center pl-3 mb-3 font-medium">
              판매현황
              <MdKeyboardArrowRight className="ml-2 text-xl" />
            </h5>
          </Link>

          <div className="flex w-full h-full max-h-[244px] gap-5">
            <div className="w-1/2 rounded-3xl shadow-app1 p-8 h-full max-h-[244px]">
              <p className="text-lg mb-16 font-medium">누적 판매 수</p>
              <p className="text-5xl text-primary font-bold f-center">
                {sells?.totalSalesCount
                  ? formatPricewithComma(sells.totalSalesCount)
                  : "0"}
                <span className="text-base text-gray font-normal ml-1">건</span>
              </p>
            </div>
            <div className="w-1/2 rounded-3xl shadow-app1 p-8 h-full max-h-[244px]">
              <p className="text-lg mb-16 font-medium">이번 달 판매 수</p>
              <p className="text-5xl text-primary font-bold f-center">
                {sells?.monthSalesCount
                  ? formatPricewithComma(sells?.monthSalesCount)
                  : "0"}
                <span className="text-base text-gray font-normal ml-1">건</span>
              </p>
            </div>
          </div>
        </div>

        <div className="w-full col-span-full mt-8 lg:mt-0 lg:col-span-3">
          <div className="flex items-center justify-between mb-3">
            <Link to="/admin/settlement/records">
              <h5 className="flex items-center pl-3 font-medium">
                정산현황
                <MdKeyboardArrowRight className="ml-2 text-xl" />
              </h5>
            </Link>
            <p className="text-gray text-sm">
              {formatDate(dateNow, "dot")} 기준
            </p>
          </div>

          <div className="w-full rounded-3xl shadow-app1 p-8 h-full max-h-[244px]">
            <p className="text-lg mb-16 font-medium">정산 가능금액</p>
            <p className="text-xl md:text-3xl xl:text-4xl 2xl:text-5xl text-primary font-bold f-center">
              {formatPricewithComma(reqPrice?.withval?.paid_price) || "0"}
              <span className="text-base text-gray font-normal ml-1">원</span>
            </p>
          </div>
        </div>
      </section>

      <section className="chart_section flex flex-wrap lg:flex-nowrap mt-8">
        <div className="w-full">
          <div className="flex items-center justify-between mb-3">
            <h4 className="flex items-center pl-3 font-medium">매출통계</h4>
            <p className="text-gray text-sm">
              {formatDate(dateNow, "dot")} 기준
            </p>
          </div>

          <div className="shadow-app1 p-5 rounded-3xl">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <Button
                  variant={
                    chartFiltered.type === "COUNT" ? "contained" : "default"
                  }
                  className={
                    "!rounded-full ml-2" +
                    (chartFiltered.type === "COUNT" ? "" : " !text-gray")
                  }
                  onClick={() => handleChangeChartFilter("value", "COUNT")}
                >
                  판매건수
                </Button>
                <Button
                  variant={
                    chartFiltered.type === "AMOUNT" ? "contained" : "default"
                  }
                  className={
                    "!rounded-full ml-2" +
                    (chartFiltered.type === "AMOUNT" ? "" : " !text-gray")
                  }
                  onClick={() => handleChangeChartFilter("value", "AMOUNT")}
                >
                  판매금액
                </Button>
              </div>

              <div className="flex items-center">
                <Button
                  variant={
                    chartFiltered.date === "DAY" ? "contained" : "default"
                  }
                  className={
                    "!rounded-full " +
                    (chartFiltered.date === "DAY" ? "" : " !text-gray")
                  }
                  onClick={() => handleChangeChartFilter("date", "DAY")}
                >
                  일별
                </Button>
                <Button
                  variant={
                    chartFiltered.date === "MONTH" ? "contained" : "default"
                  }
                  className={
                    "!rounded-full ml-2" +
                    (chartFiltered.date === "MONTH" ? "" : " !text-gray")
                  }
                  onClick={() => handleChangeChartFilter("date", "MONTH")}
                >
                  월별
                </Button>
                <Button
                  variant={
                    chartFiltered.date === "YEAR" ? "contained" : "default"
                  }
                  className={
                    "!rounded-full ml-2" +
                    (chartFiltered.date === "YEAR" ? "" : " !text-gray")
                  }
                  onClick={() => handleChangeChartFilter("date", "YEAR")}
                >
                  년도별
                </Button>
              </div>
            </div>

            <div className="w-full mt-16">
              <ResponsiveContainer width={"100%"} height={400}>
                <LineChart data={chartData}>
                  <Line
                    type="monotone"
                    dataKey="value"
                    strokeWidth="4px"
                    stroke="#FF6738"
                    dot={{ strokeWidth: 6 }}
                  />
                  <CartesianGrid
                    stroke="#E4E4E4"
                    strokeDasharray={[3, 3]}
                    vertical={false}
                  />
                  <XAxis
                    dataKey="date"
                    stroke="#B0B0B0"
                    tickLine={false}
                    padding={{ left: 50 }}
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis
                    axisLine={false}
                    stroke="#B0B0B0"
                    domain={[0, "dataMax + 500"]}
                    tick={{ fontSize: 12 }}
                    tickCount={10}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
