import axios from "axios";
import config from "../config/config";
import cookieHelper from "../utils/cookieHelper";

// const token = cookieHelper.getCookie();

const http = axios.create({
  baseURL: config.apiUrl,
  // headers: {
  //   // 추가
  //   "Access-Control-Allow-Origin": `http://211.253.26.121:4088, http://121.140.146.95:3001`,
  //   "Access-Control-Allow-Credentials": "true",
  // },
});

http.interceptors.request.use((config) => {
  const token = cookieHelper.getCookie("seller_access_token");
  // console.log("Token inside interceptor: ", token);
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

// http.interceptors.request.use((config) => {
//   if (token) {
//     config.headers["Authorization"] = `Bearer ${token}`;
//   }
//   return config;
// });

// http.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     console.log("Error Response:", error);
//     return Promise.reject(error);
//   }
// );

// http.defaults.withCredentials = true;

// Interceptor for all outgoing api calls
http.interceptors.request.use((config, _onReject) => {
  return config;
});

//-- 상품 APIs
export const productList = (params, headers) =>
  http.get("/product/list", { params, headers });
export const createProduct = (data, params, headers) =>
  http.post("/api/products", data.data, { params, headers });
export const uploadForm = (params, headers) =>
  http.get("/product/uploadform", { params, headers });
export const productFilters = (params, headers) =>
  http.get("/product/ajaxfilters", { params, headers });
export const addProductFile = (data, params, headers) =>
  http.post("/product/addfile", data.data, { params, headers });
export const addProductThumb = (data, params, headers) =>
  http.post("/product/addthumb", data.data, { params, headers });
export const deleteFile = (data, params, headers) =>
  http.post("/product/delfile", data.data, { params, headers });
export const deleteThumb = (data, params, headers) =>
  http.post("/product/delthumb", data.data, { params, headers });

//-- 판매 APIs
export const salesTodayChart = (params, headers) =>
  http.get("/sales/todaychart", { params, headers });
export const salesList = (params, headers) =>
  http.get("/admins/sell", { params, headers });
export const settlementRecords = (params, headers) =>
  http.get("/sales/withdraw", { params, headers });

// 회원 APIs
export const getProfile = (params, headers) =>
  http.get("/admind/profile", { params, headers });
export const retireUser = (data, params, headers) =>
  http.delete(`/users/${data.id}`, data, { params, headers });
export const getProfileImage = (params, headers) =>
  http.get("/user/getp", { params, headers, responseType: "blob" });
export const updateDesigner = (data, params, headers) =>
  http.post("/admind/updatedp", data.data, { params, headers });
export const updateBusiness = (data, params, headers) =>
  http.post("/user/updatebusiness", data.data, { params, headers });
export const updateProfileImage = (data, params, headers) =>
  http.post("/user/updatedp", data.data, { params, headers });

// 정산 APIs
export const getUserReqs = (params, headers) =>
  http.get("/admins/reqs", { params, headers });
export const requestSettlement = (formData, params, headers) =>
  http.post("/sales/reqwithdraw", formData, { params, headers });

// 대시보드 APIs
export const getProdudctsState = (params, headers) =>
  http.get("/products/status", { params, headers });
export const getSellState = (params, headers) =>
  http.get("/products/sales-count", { params, headers });
export const getSellChart = (params, headers) =>
  http.get("/products/sales-statistics", { params, headers });
export const getThumbnail = (params, headers) =>
  http.get("/product/getth", { params, headers, responseType: "blob" });
export const getThumbnailFull = (data, params, headers) =>
  http.get(`${data.url}`, { params, headers, responseType: "blob" });
export const delProducts = (data, params, headers) =>
  http.post("/product/delproducts", data.data, { params, headers });

// 공지 APIs
export const getNotices = (params, headers) =>
  http.get("/v1/notices", { params, headers });

// 인증 APIs
export const signIn = (data, params, headers) =>
  http.post("/api/auth/login", data.data, { params, headers });
export const sendFindEmailsms = (data, params, headers) =>
  http.post("/api/auth/getsmsfindid", data.data, { params, headers });
export const checkEamilsms = (data, params, headers) =>
  http.post("/api/auth/chkfindidsms", data.data, { params, headers });
export const changePwd = (data, params, headers) =>
  http.post("/user/change-password", data.data, { params, headers });
export const checkEmailDuplicate = (data, params, headers) =>
  http.post("/api/auth/chkemail", data.data, { params, headers });
export const sendAuthsms = (data, params, headers) =>
  http.post("/user/getauthsms", data.data, { params, headers });
export const sendAuthsmsTwo = (data, params, headers) =>
  http.post("/user/getauthsms", data.data, {
    params,
    headers,
  });
export const checkAuthsms = (data, params, headers) =>
  http.post("/api/auth/chksignupsms", data.data, { params, headers });
export const signUp = (data, params, headers) =>
  http.post("/api/users/designers", data.data, { params, headers });

export const downloadExcel = async (urlParams) => {
  const token = cookieHelper.getCookie("token");
  try {
    const response = await axios.get(`${config.apiUrl}/sales/withdraw/excel`, {
      params: urlParams,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Settlements.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (err) {
    console.error("Error downloading excel file:", err);
    throw err;
  }
};
