import React, { useState } from "react";
import { IoMdCopy } from "react-icons/io";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import {
  Button,
  Input,
  Pagination,
  RadioButton,
  Select,
  Spinner,
} from "../../components";
import { delProducts, getProfile, productList } from "../../apis";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { userIdState } from "../../atoms";
import {
  formatDiscount,
  formatPrice,
  formatPricewithComma,
  formatProductState,
  formatSubcate,
} from "../../utils/formatData";
import formatDate from "../../utils/formatDate";
import { Link, useNavigate } from "react-router-dom";
import ProductThumbnail from "../../components/ProductThumbnail";
import { toast } from "react-hot-toast";
import { history } from "../../utils/history";

const Products = () => {
  const navigate = useNavigate();
  const [searchHistory, setSearchHistory] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [allCheck, setAllCheck] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const userId = useRecoilValue(userIdState);
  const [inputFocused, setInputFocused] = useState(false);
  const today = new Date();
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState({
    currentPage: 1,
    totalCount: 0,
    pageSize: 15,
    loading: false,
  });

  const [filteredData, setFilteredData] = useState({
    status: "all",
    startDate: formatDate("2019.01.01"),
    endDate: formatDate(today),
    sort: "createdAt",
  });
  const [keyword, setKeyword] = useState("");

  const fetchData = async (params, headers) => {
    setPage({ ...page, loading: true });
    try {
      const { data } = await productList(params, headers);
      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      const { page: npage, perPage, total } = data;
      setProducts(data.products);
      setPage({
        ...page,
        currentPage: npage,
        pageSize: perPage,
        totalCount: total,
        loading: false,
      });
    } catch (err) {
      setPage({ ...page, loading: false });
      console.log(err);
    }
  };

  const handleSearch = () => {
    setSearchHistory(true);
    fetchData({
      userId: userId,
      page: page.currentPage,
      perPage: page.pageSize,
      startDate: filteredData.startDate,
      endDate: filteredData.endDate,
      status: filteredData.status === "all" ? null : filteredData.status,
      sort: filteredData.sort,
      keyword: keyword,
    });
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setFilteredData({ ...filteredData, [name]: value });
  };

  const handleInitSearch = () => {
    setFilteredData({
      status: "all",
      startDate: formatDate(today),
      endDate: formatDate(today),
      sort: "createdAt",
    });
    setKeyword("");
  };

  useEffect(() => {
    if (!searchHistory) {
      fetchData({
        userId: userId,
        page: page.currentPage,
        perPage: page.pageSize,
        startDate: filteredData.startDate,
        endDate: filteredData.endDate,
        status: filteredData.status === "all" ? null : filteredData.status,
        sort: filteredData.sort,
      });
    }
  }, [page.currentPage, filteredData, searchHistory]);

  const handleClickToggleEditMode = (e, bool) => {
    setEditMode(bool);
    if (!bool) setSelectedIds([]);
  };

  const handleClickSelectAll = (e) => {
    if (selectedIds.length === products.length) {
      setAllCheck(false);
      return setSelectedIds([]);
    }

    const ids = products.map((ele) => ele.id);
    setAllCheck(true);
    setSelectedIds([...ids]);
  };

  const handleClickToggleEdit = (e, id) => {
    const index = selectedIds.indexOf(id);
    if (index !== -1) selectedIds.splice(index, 1);
    else selectedIds.push(id);
    setSelectedIds([...selectedIds]);
  };

  const handlePageChange = (value) => {
    if (value === page.currentPage) return;
    setPage({ ...page, currentPage: value });
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  // 여러 상품 한번에 삭제
  const handleRemoveProducts = async () => {
    try {
      const selectedIdstoString = selectedIds.join(",");
      const data = await delProducts({ data: { ids: selectedIdstoString } });
      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      const updatedProducts = products.filter(
        (product) => !selectedIds.includes(product.id)
      );
      setProducts(updatedProducts);

      setEditMode(false);
    } catch (err) {
      console.log(err);
    }
  };

  // 상품 한개 삭제
  const handleRemoveProduct = async (id) => {
    try {
      const data = await delProducts({ data: { ids: id } });
      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      toast.success("상품이 삭제되었습니다");

      const newProducts = products.filter((product) => product.id !== id);
      setProducts(newProducts);
    } catch (err) {
      console.log(err);
    }
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    const listenBackEvent = () => {
      if (searchHistory) {
        setKeyword("");
        setSearchHistory(false);
        navigate("/admin/products/list");
      }
    };

    const unlistenHistoryEvent = history.listen(({ action }) => {
      if (action === "POP" && searchHistory) {
        listenBackEvent();
        console.log("?!!");
        setSearchHistory(false);
      }
    });

    return unlistenHistoryEvent;
  }, [searchHistory]);

  return (
    <div className="product_page">
      <section className="flex items-center justify-between page_header">
        <h2 className="text-base md:text-lg">상품 조회/수정</h2>
        <div className="flex">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <Input
              type="search"
              variant="outlined"
              color="gray"
              placeholder="어떤 상품을 찾으시나요?"
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              value={keyword}
              name="keyword"
              onChange={(event) => setKeyword(event.target.value)}
              onKeyPress={handleKeyPressSearch}
              className="md:min-w-[320px] pl-10"
            />
          </div>
          <Button
            color={inputFocused ? "primary" : "gray"}
            variant="contained"
            className="!rounded-full ml-4"
            onClick={handleSearch}
          >
            검색
          </Button>
        </div>
      </section>

      <hr className="my-6 border-gray-200" />

      <section className="products_container">
        <div className="filters actions">
          {!editMode ? (
            <div className="flex flex-wrap items-center products_filtering">
              <div className="max-w-xs ml-4">
                <Select
                  value={filteredData.status}
                  name="status"
                  onChange={handleChangeData}
                  className="!text-gray-500"
                >
                  <option value="all">전체상품</option>
                  <option value="WAITING">승인대기</option>
                  <option value="COMPLETE">승인완료</option>
                  <option value="REJECT">승인반려</option>
                </Select>
              </div>
              <div className="max-w-xs ml-4">
                <Select
                  defaultValue="createdAt"
                  className="!text-gray-500"
                  onChange={handleChangeData}
                >
                  <option value="createdAt">상품등록일</option>
                  <option value="updatedAt">최종수정일</option>
                </Select>
              </div>
              <div className="max-w-xs ml-4">
                <Input
                  type="date"
                  variant="outlined"
                  color="gray"
                  name="startDate"
                  value={filteredData.startDate}
                  onChange={handleChangeData}
                  max={filteredData.endDate}
                  className="!py-1.5"
                />
              </div>
              <span className="block ml-2">~</span>
              <div className="max-w-xs ml-2">
                <Input
                  type="date"
                  variant="outlined"
                  color="gray"
                  name="endDate"
                  value={filteredData.endDate}
                  min={filteredData.startDate}
                  max={formatDate(today)}
                  onChange={handleChangeData}
                  className="!py-1.5"
                />
              </div>
              <div className="mt-2 xl:mt-0 xl:ml-auto">
                <Button
                  color={
                    filteredData.status !== "all" || filteredData.endDate !== ""
                      ? "primary"
                      : "gray"
                  }
                  variant="outlined"
                  size="sm"
                  className="!rounded-full mr-2"
                  onClick={handleInitSearch}
                >
                  검색조건 초기화
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  className="!rounded-full"
                  onClick={(e) => handleClickToggleEditMode(e, true)}
                >
                  편집
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap items-start justify-between px-4 product_editing">
              <div className="flex items-center">
                <RadioButton
                  checked={selectedIds.length === products.length}
                  onClick={handleClickSelectAll}
                />
                <Button
                  color="primary"
                  size="sm"
                  className="ml-4 hover:bg-white"
                  onClick={handleClickSelectAll}
                >
                  전체선택
                </Button>
              </div>
              <div className="flex items-start">
                <Button color="gray" size="sm" className="ml-4">
                  {allCheck ? selectedIds.length - 2 : selectedIds.length}개
                  선택
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="sm"
                  className="ml-4"
                  onClick={handleRemoveProducts}
                >
                  선택삭제
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="sm"
                  className="ml-4"
                  onClick={(e) => handleClickToggleEditMode(e, false)}
                >
                  편집닫기
                </Button>
              </div>
            </div>
          )}
        </div>

        <hr className="my-6 border-gray" />

        <div className="px-4 product_list">
          {products
            .filter((product) => ["1", "2", "10"].includes(product.st))
            .map((product, i) => (
              <div
                key={product.id || i}
                className={`product_info flex pb-33 mb-4 border-b border-b-gray-300 ${
                  editMode && selectedIds.indexOf(product.id || i) === -1
                    ? "opacity-50"
                    : ""
                }`}
              >
                <div
                  className={`product_status flex ${
                    editMode ? "items-center" : ""
                  } self-stretch min-w-[80px]`}
                >
                  {!editMode && (
                    <span
                      className={
                        "text-sm whitespace-nowrap " +
                        (product?.st !== "10"
                          ? "text-primary"
                          : "text-gray-500")
                      }
                    >
                      {formatProductState(product?.st)}
                    </span>
                  )}
                  {editMode && (
                    <RadioButton
                      checked={
                        selectedIds.indexOf(product.id || i) === -1
                          ? false
                          : true
                      }
                      onClick={(e) => handleClickToggleEdit(e, product.id || i)}
                    />
                  )}
                </div>
                <div className="w-full ml-4">
                  {product?.st === "2" ? (
                    <p className="text-sm product_dates text-primary">
                      {product?.note}
                    </p>
                  ) : (
                    <p className="text-sm product_dates text-gray">
                      상품등록일 {formatDate(product.createdAt) || "-"} /
                      최종수정일 {formatDate(product.updatedAt) || "-"}
                    </p>
                  )}
                  <div className="flex items-center justify-between mt-4 mb-4">
                    <div className="flex items-center">
                      <div className="w-28 h-28">
                        <ProductThumbnail url={product.mainThumbSrc} />
                      </div>

                      <div className="ml-8 font-medium product_details">
                        <h3 className="mb-2 text-sm product_name text-primary">
                          {formatSubcate(product.subcate)}
                        </h3>
                        <p className="max-w-sm mb-2 product_description">
                          {product.name}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-10">
                      <div className="">
                        {/* <p className="gap-2 text-sm font-semibold product_discount f-center">
                          <span className="text-primary">
                            {formatDiscount(
                              parseInt(product.price),
                              parseInt(formatPrice(product.price_tiers_id))
                            ) || "0"}
                            %
                          </span>
                          <span className="line-through text-gray">
                            {formatPricewithComma(product.price)} 원
                          </span>
                        </p> */}
                        <p className="font-medium product_price text-end">
                          {formatPrice(product.priceTiersId)} 원
                        </p>
                      </div>
                      <div className="flex gap-3 f-center">
                        <Link to={`/admin/products/edit/${product.id}`}>
                          {" "}
                          <Button
                            variant="contained"
                            color="primary"
                            icon={true}
                          >
                            <BsPencilSquare className="text-2xl" />
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color="primary"
                          icon={true}
                          onClick={() => handleRemoveProduct(product.id)}
                        >
                          <BsTrash className="text-2xl" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className="mt-10 pagination">
          <Pagination
            pageSize={page.pageSize}
            onPageChange={handlePageChange}
            totalCount={page.totalCount}
            currentPage={page.currentPage}
          />
        </div>

        {page.loading && (
          <div className="w-full min-h-[300px] f-center">
            <Spinner />
          </div>
        )}
      </section>
    </div>
  );
};

export default Products;
