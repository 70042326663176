import React, { useEffect, useState } from "react";

import { Button, Input } from "../../components";
import { getNotices } from "../../apis";
import formatDate from "../../utils/formatDate";

const Announcement = () => {
  const types = [
    { value: "ALL", text: "전체" },
    { value: "NORMAL", text: "일반" },
    { value: "SYSTEM", text: "시스템" },
    { value: "MANUAL", text: "메뉴얼" },
  ];
  const [notices, setNotices] = useState([]);
  const [currentType, setCurrentType] = useState("ALL");
  const [searchKeyword, setSearchKeyword] = useState("");

  const getTypeText = (typeValue) => {
    const type = types.find((type) => type.value === typeValue);
    return type ? type.text : typeValue;
  };

  const fetchNotices = async (params, headers) => {
    try {
      const { data } = await getNotices(params, headers);

      if (data.status > 299 || data.status < 200) {
        const message =
          data && data.message ? data.message : "Something went wrong";
        throw new Error(message);
      }

      setNotices(data.notices);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearch = () => {
    fetchNotices({
      category: "SELLER",
      keyword: searchKeyword,
    });
  };

  useEffect(() => {
    fetchNotices({
      category: "SELLER",
      type: currentType,
    });
  }, [currentType]);

  return (
    <div className="announcement_page">
      <section className="page_header px-4">
        <h2 className="text-base">공지사항</h2>
      </section>

      <hr className="border-gray-200 my-6" />

      <section className="announcements px-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {types.map((type) => (
              <Button
                size="sm"
                variant={type.value === currentType ? "contained" : "default"}
                onClick={() => setCurrentType(type.value)}
                color={type.value === currentType ? "primary" : " gray"}
                className="mr-2"
              >
                {type?.text}
              </Button>
            ))}
          </div>
          <div className="flex">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <Input
                type="search"
                variant="outlined"
                color="gray"
                placeholder="검색어를 입력하세요"
                className="md:min-w-[320px] pl-10 !text-font-input"
                name="search"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
            </div>
            <Button
              color={searchKeyword !== "" ? "primary" : "gray"}
              variant="contained"
              className="!rounded-full ml-4"
              onClick={handleSearch}
            >
              검색
            </Button>
          </div>
        </div>

        <hr className="border-gray-300 my-6" />

        {notices.map((notice, i) => (
          <div
            key={i}
            className="flex items-center justify-between font-normal pb-4 mb-5 border-b border-b-gray-200 last:border-b-0"
          >
            <div className="flex items-center">
              <p className="pl-4">{`[${getTypeText(notice.type)}] ${
                notice.title
              }`}</p>
              <Button
                icon
                variant="contained"
                color="primary"
                size="xs"
                className="ml-4"
              >
                N
              </Button>
            </div>
            <p className="text-gray">{formatDate(notice.createdAt)}</p>
          </div>
        ))}
      </section>
    </div>
  );
};

export default Announcement;
